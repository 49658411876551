/* Desktop Responsive */
@media only screen and (min-width: 940px) {
.emotionalIntelligence {
  background: linear-gradient(-73.83deg, #ffd600, #ffe766);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.noChildWillContainer {
  align-self: stretch;
  position: relative;
  line-height: 62px;
}
.genxtureBringsTheContainer {
  position: relative;
  font-size: var(--font-size-3xl);
  line-height: 35.2px;
  font-weight: 600;
}
.startLearning {
  position: relative;
  line-height: 25px;
  font-weight: 600;
}
.a {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  width: 236px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-base);
}
.downloadApp {
  position: relative;
  line-height: 67px;
}
.appStoresvgIcon,
.playStoresvgIcon {
  position: relative;
  width: 165.17px;
  height: 49.42px;
  flex-shrink: 0;
  overflow: hidden;
}
.appStoresvgIcon {
  width: 145px;
  height: 49.84px;
}
.playStoresvgParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.div,
.div1 {
  display: flex;
  gap: var(--gap-5xl);
}
.div1 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-lg);
}
.div {
  width: 580px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.heroIcon {
  position: relative;
  width: 660px;
  height: 440px;
  flex-shrink: 0;
  overflow: hidden;
}

.divParent {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: var(--padding-77xl) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  z-index: 3;
  text-align: left;
  font-size: var(--font-size-29xl);
  color: var(--white);
  font-family: var(--font-inter);
}

}


/* Mobile Responsive */
@media only screen and (max-width: 940px) {
.emotionalIntelligence {
  background: linear-gradient(-73.83deg, #ffd600, #ffe766);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.noChildWillContainer {
  align-self: stretch;
  position: relative;
  line-height: 1.6;
  font-size: 26px;
}
.genxtureBringsTheContainer {
  position: relative;
  font-size: 16px;
  padding: 10px;
  font-weight: 600;
  margin: auto;
}
.startLearning {
  position: relative;
  line-height: 25px;
  font-weight: 600;
}
.a {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  width: 236px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-base);
  margin: auto;
  cursor: pointer;
}
.downloadApp {
  position: relative;
  padding: 20px;
  line-height: 20px;
  clear: both;
  white-space: pre;
  display: block;
}
.appStoresvgIcon,
.playStoresvgIcon {
  position: relative;
  width: 165.17px;
  height: 49.42px;
  flex-shrink: 0;
  overflow: hidden;
  cursor: pointer;
}
.appStoresvgIcon {
  width: 145px;
  height: 49.84px;
  cursor: pointer;
}
.playStoresvgParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
}
.div,
.div1 {
  display: flex;
  gap: var(--gap-5xl);
}
.div1 {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-lg);
  display: block;
}
.div {
  position: relative;
  top: 440px;
  width: 100%;
  flex-shrink: 0;
  text-align: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.heroIcon {
  top: -190px;
  position: relative;
  height: 320px;
  flex-shrink: 0;
  overflow: hidden;
}


.divParent {
  width: 100%;
  padding-top: 20px;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  z-index: 3;
  text-align: left;
  font-size: var(--font-size-29xl);
  color: var(--white);
  font-family: var(--font-inter);
  display:flex;
  flex-direction:column-reverse;
}

}