/* Desktop Responsive */
@media only screen and (min-width: 940px) {

.meetOurBest {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
}
.bestMindsDemand {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 671px;
  opacity: 0.8;
}
.div {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.imageIcon {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-base);
  width: 295px;
  height: 295px;
  overflow: hidden;
  object-fit: cover;
}
.janeCooper,
.principalConsultant {
  position: relative;
  line-height: 160%;
  font-weight: 600;
  display: inline-block;
  width: 292.78px;
}
.principalConsultant {
  font-size: var(--font-size-lg);
  font-weight: 500;
  opacity: 0.6;
}
.info {
  position: absolute;
  top: 321.48px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.teamCard {
  align-self: stretch;
  position: relative;
  width: 295px;
  flex-shrink: 0;
  font-size: 22.96px;
}
.line {
  height: 410.46px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--font-size-5xl);
}
.shape,
.shapeIcon {
  position: relative;
  height: 10px;
  flex-shrink: 0;
}
.shape {
  border-radius: var(--br-lgi);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  width: 48px;
}
.shapeIcon {
  width: 10px;
}
.slider,
.team {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  top: 90px;
  position: relative;
}
.team {
  background-color: var(--color-white);
  width: 100%;
  height: 900px;
  flex-direction: column;
  /*padding: var(--padding-53xl) 0;*/
  box-sizing: border-box;
  align-items: center;
  /*justify-content: center;*/
  gap: var(--gap-17xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}


}

/* Mobile Responsive */
@media only screen and (max-width: 940px) {

.meetOurBest {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
}
.bestMindsDemand {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 671px;
  opacity: 0.8;
  width: 100%;
}
.div {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.imageIcon {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-base);
  width: 295px;
  height: 295px;
  overflow: hidden;
  object-fit: cover;
}
.janeCooper,
.principalConsultant {
  position: relative;
  line-height: 160%;
  font-weight: 600;
  display: inline-block;
  width: 292.78px;
}
.principalConsultant {
  font-size: var(--font-size-lg);
  font-weight: 500;
  opacity: 0.6;
}
.info {
  position: absolute;
  top: 321.48px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.teamCard {
  align-self: stretch;
  position: relative;
  width: 295px;
  flex-shrink: 0;
  font-size: 22.96px;
}
.line {
  /*display: none;*/
  height: 2100px;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--font-size-5xl);
}
.shape,
.shapeIcon {
  position: relative;
  height: 10px;
  flex-shrink: 0;
}
.shape {
  border-radius: var(--br-lgi);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  width: 48px;
}
.shapeIcon {
  width: 10px;
}
.slider{
  display: none !important;
}
.slider,
.team {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.team {
  height: auto;
  background-color: var(--color-white);
  width: 100%;
  flex-direction: column;
  padding: var(--padding-53xl) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-17xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}


}  