.students {
  position: relative;
  line-height: 23px;
  cursor: pointer;
}

.students {
  text-decoration: none;
}

.students:hover::after {
  transform-origin: left top;
  transform: scale(1, 1);
}

.aafterIcon {
  position: relative;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}
.text {
  position: relative;
  line-height: 23px;
  font-family: var(--font-jost);
}
.a {
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xl) var(--padding-4xs) var(--padding-5xl)
    var(--padding-sm);
  align-items: center;
  /*justify-content: center;*/
  
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--white);
  font-family: var(--font-inter);
}
.a:hover{
  background:#5b0346;
}

@media only screen and (max-width: 940px) {
  .a:hover{
    background: linear-gradient(-73.83deg, #3559ff, #5b10cd)
  }
}