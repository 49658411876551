/*Computer*/
@media screen and (min-width: 940px) {

.partnerDiv {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
  margin: auto;
}


.partnerThingsYourChild {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
}
.partnerKnowWhatMajor {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 611px;
  opacity: 0.8;
}

}



/*Mobile*/
@media screen and (max-width: 940px) {

.partnerDiv {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}

.partnerThingsYourChild {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 32px;
  padding: 10px;
}
.partnerKnowWhatMajor {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 80%;
  opacity: 0.8;
  font-size: 14px;
  margin: auto;
}

}	