@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
  overflow-x:hidden;
}
:root {
  /* fonts */
  overflow-x:hidden ;
  --font-lato: Lato;
  --font-inter: Inter;
  --font-jost: Jost;

  /* font sizes */
  --font-size-base: 16px;
  --font-size-xl: 20px;
  --font-size-3xl: 22px;
  --font-size-21xl: 40px;
  --font-size-lg: 18px;
  --font-size-25xl: 44px;
  --font-size-9xl: 28px;
  --font-size-348xl: 367px;
  --font-size-sm: 14px;
  --font-size-13xl: 32px;
  --font-size-xs: 12px;
  --font-size-18xl-4: 37.4px;
  --font-size-113xl-3: 132.3px;
  --font-size-29xl: 48px;

  /* Colors */
  --white: #fafafa;
  --text: #212121;
  --color-gray-100: rgba(255, 255, 255, 0.5);
  --color-gray-200: rgba(255, 255, 255, 0.2);
  --color-white: #fafafa;
  --color-whitesmoke-100: #f7f7f7;
  --color-whitesmoke-200: #f1f1f1;
  --color-lavender: #e2e5f1;
  --color-orange: #ffbe02;
  --color-gainsboro: #e3e3e3;

  /* Gaps */
  --gap-7xs: 6px;
  --gap-6xs: 7px;
  --gap-sm: 14px;
  --gap-11xs: 2px;
  --gap-17xl: 36px;
  --gap-41xl: 60px;
  --gap-5xl: 24px;
  --gap-base: 16px;
  --gap-331xl: 350px;
  --gap-xl: 20px;
  --gap-xs: 12px;
  --gap-5xs: 8px;
  --gap-11xl: 30px;
  --gap-8xs-1: 4.1px;

  /* Paddings */
  --padding-3xs: 10px;
  --padding-12xs: 1px;
  --padding-mini: 15px;
  --padding-53xl: 72px;
  --padding-2xs-7: 10.7px;
  --padding-10xs: 3px;
  --padding-xs: 12px;
  --padding-17xl: 36px;
  --padding-11xs: 2px;
  --padding-5xs: 8px;
  --padding-7xs: 6px;
  --padding-xl: 20px;
  --padding-6xl: 25px;
  --padding-77xl: 96px;
  --padding-9xs: 4px;
  --padding-5xl: 24px;
  --padding-4xs: 9px;
  --padding-sm: 14px;

  /* border radiuses */
  --br-13xl: 32px;
  --br-3xs: 10px;
  --br-5xs: 8px;
  --br-34xl-3: 53.3px;
  --br-lgi: 19px;
  --br-xl: 20px;
  --br-11xl: 30px;
  --br-42xl: 61px;
  --br-17xl: 36px;
  --br-xs: 12px;
}


/*@media only screen and (min-width: 1100px) and (max-width: 1250px) {
  #root {
  width:1180px;
  margin: 0 auto;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1350px) {
  #root {
  width:1300px;
  margin: 0 auto;
  }
}*/

@media only screen and (min-width: 1470px) {
  #root {
  width:1470px;
  margin: 0 auto;
  }
}