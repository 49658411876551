/* Desktop Responsive */
@media only screen and (min-width: 940px) {

.CarrerPara {
  font-size: 16px;
  color: #212121;
}

.unionIcon1 {
  position: relative;
  width: 100%;
  height: 1418px;
}

.unionIcon2 {
  display: none;
}

.theGenxtureEndeavor {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 551px;
  color: black;
}
.valueThatDefines {
  position: relative;
  line-height: 25px;
  font-weight: 500;
}
.a {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  width: 250px;
  padding: var(--padding-3xs) var(--padding-17xl);
  align-items: center;
  justify-content: center;
  color: var(--white);
}
.a,
.a1,
.tabs {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;
}
.a1 {
  border-radius: var(--br-11xl);
  width: 250px;
  padding: var(--padding-3xs) var(--padding-17xl);
  align-items: center;
  justify-content: center;
}
.tabs {
  border-radius: var(--br-42xl);
  background-color: var(--white);
  height: 61px;
  padding: var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-base);
  color: var(--text);
}
.divChild {
  position: relative;
  width: 75px;
  height: 75px;
  flex-shrink: 0;
  object-fit: cover;
}
.curiosity {
  position: relative;
  line-height: 45px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 20px;
}
.buildTrustingRelationships {
  position: relative;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 336.47px;
  font-size: 16px;
}
.div,
.div1 {
  border-radius: var(--br-xl);
  border: 1px solid #E2E5F1;
  width: 430px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xl);
  padding: 24px;
}
.div {
  color: black;
  border: 1px solid #cfcfcf;
  font-size: 20px;
  background-color: #fff;
}
.div1 {
  align-self: stretch;
  color: black;
  border: 1px solid #cfcfcf;
  background-color: #fff;
}
.buildTrustingRelationships2 {
  position: relative;
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 336.47px;
  height: 85px;
  flex-shrink: 0;
  font-size: 16px;
}
.div2 {
  color: black;
  border: 1px solid #cfcfcf;
  align-self: stretch;
  border-radius: var(--br-xl);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 400px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-5xl);
  padding: 12.5px;
}
.div2,
.div3,
.divParent {
  align-items: flex-start;
}
.divParent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: left;
}
.div3 {
  align-self: stretch;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #44a659, #3cff65);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 400px;
  flex-shrink: 0;
  padding: var(--padding-11xl);
  gap: var(--gap-5xl);
}
.div3,
.div4,
.ourCulture {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
}
.div4 {
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #232323, #8a8a8a);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 400px;
  flex-shrink: 0;
  padding: var(--padding-11xl);
  align-items: flex-start;
  gap: var(--gap-5xl);
}
.ourCulture {
  position: absolute;
  top: 174px;
  left: 0;
  width: 100%;
  padding: var(--padding-53xl) 0;
  align-items: center;
  gap: var(--gap-17xl);
}
.numbers {
  position: relative;
  width: 100%;
  height: 800px;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--white);
  font-family: var(--font-inter);
}

}


/* Mobile Responsive */
@media only screen and (max-width: 940px) {

.CarrerPara {
  font-size: 16px;
  color: #212121;
  width: 80%;
}

.unionIcon2 {
  position: relative;
  width: 100%;
  height: auto;
  left: 0;
}

.unionIcon1 {
 display: none;
}


.theGenxtureEndeavor {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.valueThatDefines {
  position: relative;
  line-height: 25px;
  font-weight: 500;
}
.a {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  width: 250px;
  padding: var(--padding-3xs) var(--padding-17xl);
  align-items: center;
  justify-content: center;
  color: var(--white);
}
.a,
.a1,
.tabs {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}
.a1 {
  border-radius: var(--br-11xl);
  width: 250px;
  padding: var(--padding-3xs) var(--padding-17xl);
  align-items: center;
  justify-content: center;
}
.tabs {
  width: 90%;
  border-radius: var(--br-42xl);
  background-color: var(--white);
  height: 61px;
  padding: var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-base);
  color: var(--text);
}
.divChild {
  position: relative;
  width: 75px;
  height: 75px;
  flex-shrink: 0;
  object-fit: cover;
}
.curiosity {
  position: relative;
  line-height: 45px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 20px;
}
.buildTrustingRelationships {
  position: relative;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.div,
.div1 {
  border-radius: var(--br-xl);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xl);
}
.div {

}
.div1 {
  align-self: stretch;

}
.buildTrustingRelationships2 {
  position: relative;
  line-height: 28px;
  display: flex;
  align-items: center;
  height: 85px;
  flex-shrink: 0;
  font-size: 16px;
}
.div2 {
  align-self: stretch;
  border-radius: var(--br-xl);
  width: 90%;
  margin: auto;
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-5xl);
  padding: 20px;
}
.div2,
.div3,
.divParent {
  align-items: flex-start;
}
.divParent {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: left;
}
.div3 {
  align-self: stretch;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #44a659, #3cff65);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 100%;
  flex-shrink: 0;
  padding: 10px; 
  gap: var(--gap-5xl);
  margin: 0px auto 20px auto;
}
.div3,
.div4,
.ourCulture {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
}
.div4 {
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #232323, #8a8a8a);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 100%;
  flex-shrink: 0;
  padding: 10px;
  align-items: flex-start;
  gap: var(--gap-5xl);
}
.ourCulture {
  color: black;
  position: absolute;
  top: 174px;
  left: 0;
  width: 100%;
  padding: var(--padding-53xl) 0;
  align-items: center;
  gap: var(--gap-17xl);
}
.numbers {
  position: relative;
  width: 100%;
  height: 1500px;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--white);
  font-family: var(--font-inter);
}

}  
