.PrivacyPolicyPage {
	width: 60%;
	margin: auto;
}

.t1 {
	text-align: center;
	font-size: 34px;
	font-weight: bold;
	margin: 40px 0px;
	text-decoration: underline;
}

.t2 {
	font-weight: bold;
	margin: 10px 0px;
}

.company {
	font-weight: bold;
}