/*Computer*/
@media screen and (min-width: 940px) {

.div {
  height: 292px;
  width: 100%;
  text-align: center;
  background: linear-gradient(297.79deg, #A7048B 10.92%, #A00487 14.91%, #4A025C 61.78%, #150041 94.69%, #000037 110.65%);
}

.div h1 {
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 40px;
line-height: 48px;
align-items: center;
text-align: center;
color: #ffffff;
letter-spacing: -0.04em;
margin-top: 60px;
}

.button {
  width: 180px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  background: linear-gradient(286.17deg, #66A5FA 0%, #723AC5 100%);
  border-radius: 30px;
  font-size: 17px;
  color: #ffffff;
  font-weight: bold;
  margin: auto;
}

}



/*Mobile*/
@media screen and (max-width: 940px) {

.div {
  height: 300px;
  width: 100%;
  text-align: center;
  background: linear-gradient(297.79deg, #A7048B 10.92%, #A00487 14.91%, #4A025C 61.78%, #150041 94.69%, #000037 110.65%);
}


.div h1 {
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 48px;
align-items: center;
text-align: center;
color: #ffffff;
letter-spacing: -0.04em;
margin-top: 60px;

}


.button {
  width: 180px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  background: linear-gradient(286.17deg, #66A5FA 0%, #723AC5 100%);
  border-radius: 30px;
  font-size: 17px;
  color: #ffffff;
  font-weight: bold;
  margin: auto;
}

}