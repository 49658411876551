/* Desktop Responsive */
@media only screen and (min-width: 940px) {

.ourVision {
  letter-spacing: -0.04em;
}
.div,
.everyChildReads,
.ourVision {
  position: relative;
  font-weight: 600;
}
.div {
  letter-spacing: -0.02em;
  color: var(--color-black);
}
.everyChildReads {
  line-height: 140%;
  display: inline-block;
  width: 636px;
}
.mentalEducationIs {
  margin: 0;
}
.mentalEducationIsContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 36px;
  opacity: 0.6;
}
.text {
  width: 636px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.businessVisionPana1 {
  position: relative;
  width: 500px;
  height: 500px;
  flex-shrink: 0;
  overflow: hidden;
}
.section,
.vision {
  width: 1240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-82xl);
  text-align: left;
  font-size: var(--font-size-13xl);
}
.vision {
  background-color: var(--color-white);
  width: 100%;
  flex-direction: column;
  padding: var(--padding-53xl) 0;
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-17xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}

}


/* Mobile Responsive */
@media only screen and (max-width: 940px) {

.ourVision {
  letter-spacing: -0.04em;
}
.div,
.everyChildReads,
.ourVision {
  position: relative;
  font-weight: 600;
}
.div {
  letter-spacing: -0.02em;
  color: var(--color-black);
}
.everyChildReads {
  line-height: 140%;
  display: inline-block;
  width: 100%;
}
.mentalEducationIs {
  margin: 0;
}
.mentalEducationIsContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 36px;
  opacity: 0.6;
}
.text {
  margin: auto;
  width: 90%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.businessVisionPana1 {
  position: relative;
  width: 328px;
  height: 328px;
  flex-shrink: 0;
  overflow: hidden;
  margin: auto;
}
.section,
.vision {
  width: 100%;
  gap: var(--gap-82xl);
  text-align: left;
  font-size: var(--font-size-13xl);
  display: flex;
  flex-direction: column-reverse;
}
.vision {
  background-color: var(--color-white);
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-17xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}

}  