
.selIcon {
  height: 60px;
}

.weAtGenxture {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 100%;
  max-width: 806px;
  opacity: 0.8;
}

.div {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}

.span {
  font-size: var(--font-size-18xl-4);
}

.txt {
  line-break: anywhere;
  width: 100%;
  color: black;
}

.b {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 198.88px;
  font-size: 20px;
}



.div1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) 0;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}

.b2 {
  position: relative;
  font-size: 20px;
}

.ofIndianStudents {
  margin: 0;
}


.divGroup {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-11xl);
  font-size: 132.29px;
  color: var(--color-white);
}


/*Computer*/
@media screen and (min-width: 940px) {

.SelPara {
  font-size: 16px;
}

.howIsGenxture {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  padding-top: 160px;
}


.divParent {
  position: relative;
  width: 100%;
  max-width: 1240px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-17xl);
  margin: auto;
}

.ofAllWorking {
  align-self: stretch;
  position: relative;
  line-height: 31px;
  color: var(--white);
  font-size: 16px;
  color: #212121;
}

.unionIcon1 {
  position: absolute;
  max-width: 100%;
  height: 812;
  left: 0;
}

.unionIcon2 {
  display: none;
}

.divGroup,
.divParent {
  display: flex;
  align-items: center;
  color: black;
}


.numbers {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--white);
  font-family: var(--font-inter);
  color: black;
}


}

/*Mobile*/
@media screen and (max-width: 940px) {

.SelPara {
  font-size: 16px;
}

.howIsGenxture {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 24px;
  color: black;
}


.divParent {
  position: relative;
  top: 190.56px;
  width: 100%;
  max-width: 1240px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-17xl);
  margin: auto;
}

  .div {
    padding: 0 var(--padding-2xs);
    gap: var(--gap-2xl);
  }
  
  .weAtGenxture {
    font-size: var(--font-size-sm);
    line-height: 26px;
  }
  
  .divGroup {
    font-size: 80px;
    gap: var(--gap-5xl);
  }

.divGroup,
.divParent {
  align-items: center;
}

.divParent {
  position: relative;
  width: 100%;
  max-width: 1240px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-17xl);
}
  

.ofAllWorking {
  align-self: stretch;
  position: relative;
  line-height: 31px;
  font-size: 16px;
  padding: 5px;
  color: black;
}

.unionIcon1 {
 display: none;
}

.unionIcon2 {
  position: absolute;
  width: 100%;
  left: 0;
  max-width: 100%;
  height: auto;
}


.numbers {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--white);
  font-family: var(--font-inter);
  padding-bottom: 250px;
}


}