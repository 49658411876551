/*Computer*/
@media screen and (min-width: 940px) {

.LearningAppTypeBox {
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.LearningAppTypeBox1 {
     width: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.LearningAppTypeBox2 {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.LearningAppTypeBoxText {
  padding: 5px 20px;
  font-size: 14px;
}


.genextureProvesIts {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
}
.knowledge {
  position: relative;
  line-height: 25px;
  font-weight: 500;
}
.a {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  width: 252px;
  flex-shrink: 0;
  padding: var(--padding-5xs) var(--padding-17xl);
  box-sizing: border-box;
  justify-content: center;
  color: var(--white);
}
.a,
.a1,
.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.a1 {
  border-radius: var(--br-11xl);
  width: 252px;
  flex-shrink: 0;
  padding: var(--padding-5xs) var(--padding-17xl);
  box-sizing: border-box;
  justify-content: center;
}
.tabs {
  border-radius: var(--br-42xl);
  background-color: var(--color-whitesmoke-200);
  padding: var(--padding-11xs);
  justify-content: flex-start;
  font-size: var(--font-size-xs);
}
.materialSymbolsplayCircleOIcon,
.screenChangeMe {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.screenChangeMe {
  height: 105.46%;
  top: -2.73%;
  right: -1.54%;
  bottom: -2.73%;
  left: 15.54%;
  border-radius: var(--br-13xl);
}
.materialSymbolsplayCircleOIcon {
  height: 19.67%;
  width: 11.09%;
  top: 40.16%;
  right: 44.53%;
  bottom: 40.16%;
  left: 44.38%;
}
.screenChangeMeParent {
  position: relative;
  width: 649px;
  height: 366px;
  flex-shrink: 0;
}
.helpYourChild {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
}
.getThemReady {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 537.03px;
  opacity: 0.6;
}
.knowMore {
  position: relative;
  line-height: 25px;
  font-weight: 600;
}
.a3,
.div {
  display: flex;
}
.a3 {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-17xl);
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--white);
}
.div {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.features,
.frameParent {
  width: 1242px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: var(--font-size-13xl);
}
.features {
  background-color: var(--color-white);
  width: 100%;
  flex-shrink: 0;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-17xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}


}



/*Mobile*/
@media screen and (max-width: 940px) {


.LearningAppTypeBox {
    height: 80px;
    width: 100%;
}

.LearningAppTypeBox1 {
     width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    top: 200px;
}

.LearningAppTypeBox2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -60px;
}

.LearningAppTypeBoxText {
  padding: 20px;
  font-size: 14px;
}


.genextureProvesIts {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 24px;
}
.knowledge {
  position: relative;
  line-height: 25px;
  font-weight: 500;
}
.a {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  width: 252px;
  flex-shrink: 0;
  padding: var(--padding-5xs) var(--padding-17xl);
  box-sizing: border-box;
  justify-content: center;
  color: var(--white);
}
.a,
.a1,
.tabs {
    flex: 1;
    border-radius: var(--br-base);
    flex-direction: column;
    padding: var(--padding-5xs) var(--padding-base);
    justify-content: center;
    color: var(--white);
    border-radius: 40px;
    padding: 10px;
    margin: auto;
}
.a1 {
    flex: 1;
    border-radius: var(--br-base);
    display: flex;
    flex-direction: column;
    padding: var(--padding-5xs) var(--padding-base);
    align-items: center;
    justify-content: center;
}
.tabs {
 border-radius: var(--br-16xl);
    background-color: var(--color-whitesmoke-200);
    width: 90%;
    margin: 10px auto;
    padding: 10px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-11xs);
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-end;
    font-size: var(--font-size-xs);
    border-radius: 40px;
    color: black;
}
.materialSymbolsplayCircleOIcon,
.screenChangeMe {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.screenChangeMe {
  height: 281px;
  width: 328px;
  left: 10px;
  border-radius: var(--br-13xl);
}
.materialSymbolsplayCircleOIcon {
  height: 19.67%;
  width: 11.09%;
  top: 40.16%;
  right: 44.53%;
  bottom: 40.16%;
  left: 44.38%;
}
.screenChangeMeParent {
  position: relative;
  width: 100%;
  height: 281px;
  flex-shrink: 0;
  cursor: pointer;
}
.helpYourChild {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 32px;
  padding: 0px 25px;
}
.getThemReady {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 90%;
  opacity: 0.6;
  padding: 0px 25px;
}
.knowMore {
  position: relative;
  line-height: 25px;
  font-weight: 600;
}
.a3,
.div {
  display: flex;
}
.a3 {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-17xl);
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--white);
  margin: 0px 25px;
  cursor: pointer;
}
.div {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  margin-top: 200px;
}
.features,
.frameParent {
  width: 90%;
  margin: auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: var(--font-size-13xl);
}
.features {
  background-color: var(--color-white);
  width: 100%;
  height: 1275px;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-53xl) 0;
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-17xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}

}  


