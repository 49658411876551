/*Computer*/
@media screen and (min-width: 940px) {


.boxcontainer {
  height: auto;
  width: 90%;
  background-color: purple;
  border-radius: 30px;
  text-align: left;
}

.boxcontainer form{
   display: block;
}

.boxcontainer input[type="text"]{
   display: block;
   width: 340px;
   padding: 12px;
   margin: 20px auto;
   border-radius: 8px;
   border: none;
}

.boxcontainer input[type="submit"]{
   display: block;
   width: 140px;
   padding: 10px;
   margin: 15px auto;
   border-radius: 30px;
   cursor: pointer;
   background: linear-gradient(286.17deg, #66A5FA 0%, #723AC5 100%);
   border: none;
   color: #f2f2df;
   font-size: 18px;
}

.unionIcon {
  position: relative;
  width: 100%;
  height: 1707px;
}
.ourProcess {
  position: absolute;
  top: 286px;
  left: 540px;
  letter-spacing: -0.04em;
  font-weight: 600;
}
.divider {
  position: absolute;
  top: 166.5px;
  left: 60.5px;
  border-right: 1px solid var(--color-lavender);
  box-sizing: border-box;
  width: 1px;
  height: 765px;
  display: none;
}
.div,
.learning {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 600;
}
.learning {
  font-weight: 500;
  color: var(--white);
}
.parent,
.textInner {
  display: flex;
  justify-content: flex-start;
}
.parent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}
.textInner {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  flex-direction: column;
  padding: var(--padding-7xs) var(--padding-xl);
  align-items: flex-start;
  color: var(--color-gray-100);
}
.immersiveLearningExperience {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-13xl);
  line-height: 140%;
  font-weight: 600;
}
.withEmotioanalConnect {
  align-self: stretch;
  position: relative;
  line-height: 160%;
}
.text {
  position: absolute;
  top: 23px;
  left: 604px;
  width: 636px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.child,
.item {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.item {
  height: 91.8%;
  width: 91.8%;
  top: 7.7%;
  right: 4.1%;
  bottom: 0.49%;
  left: 4.1%;
}
.div1,
.div2,
.item {
  position: absolute;
}
.div2 {
  top: calc(50% - 21px);
  left: calc(50% - 21px);
  line-height: 130%;
  font-weight: 800;
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
}
.div1 {
  top: 89px;
  left: 0;
  width: 122px;
  height: 122px;
  text-align: center;
  font-size: var(--font-size-13xl);
}
.illustrationIcon,
.step {
  position: absolute;
  height: 300px;
}
.illustrationIcon {
  top: 0;
  padding: 50px 50px 50px 40px;
  overflow: hidden;
}
.step {
  top: -210px;
  left: 0;
  padding: 60px 30px;
  border-radius: 20px;
  width: 90%;
  background-color: purple;
}
.div4 {
  top: 83px;
  left: 0;
  width: 122px;
  height: 122px;
  text-align: center;
  font-size: var(--font-size-13xl);
}
.div4,
.step1,
.text2 {
  position: absolute;
}
.step1 {
  top: 386px;
  left: 0;
  width: 1240px;
  height: 300px;
}
.text2 {
  top: 39px;
  left: 604px;
  width: 636px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.step2,
.steps {
  position: absolute;
  top: 0;
  left: 0;
  width: 1240px;
  height: 300px;
}
.steps {
  top: 370px;
  left: 136px;
  height: 1060px;
  text-align: left;
  font-size: var(--font-size-xl);
}
.numbers {
  position: relative;
  width: 100%;
  height: 700px;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--white);
  font-family: var(--font-inter);
}

}



/*Mobile*/
@media screen and (max-width: 940px) {

.boxcontainerImg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxcontainer {
  height: 420px;
  width: 100%;
  margin: 40px auto;
  border-radius: 30px;
  text-align: left;
}

.boxcontainer form{
   display: block;
}

.boxcontainer input[type="text"]{
   display: block;
   width: 264px;
   padding: 15px;
   margin: 20px auto;
   border-radius: 8px;
   border: none;
}

.boxcontainer input[type="submit"]{
   display: block;
   width: 240px;
   padding: 10px;
   margin: 15px auto;
   border-radius: 30px;
   cursor: pointer;
   background: linear-gradient(286.17deg, #66A5FA 0%, #723AC5 100%);
   border: none;
   color: #f2f2df;
   font-size: 18px;
}

.unionIcon {
  position: relative;
  width: 100%;
  height: 1707px;
}
.ourProcess {
  position: absolute;
  top: 60px;
  margin: auto;
  text-align: center;
  left: 140px;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 24px;
}
.divider {
  display: none;
}
.div,
.learning {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 600;
}
.learning {
  font-weight: 500;
  color: var(--white);
}
.parent,
.textInner {
  display: flex;
  justify-content: flex-start;
}
.parent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}
.textInner {
  margin: auto;
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  flex-direction: column;
  padding: var(--padding-7xs) var(--padding-xl);
  align-items: flex-start;
  color: var(--color-gray-100);
}
.immersiveLearningExperience {
  align-self: stretch;
  position: relative;
  font-size: 24px;
  line-height: 140%;
  font-weight: 600;
}
.withEmotioanalConnect {
  align-self: stretch;
  position: relative;
  line-height: 160%;
  font-size: 14px;
}
.text {
  position: absolute;
  top: -410px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
}
.child,
.item {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.item {
  height: 91.8%;
  width: 91.8%;
  top: 7.7%;
  right: 4.1%;
  bottom: 0.49%;
  left: 4.1%;
}
.div1,
.div2,
.item {
  position: absolute;
}
.div2 {
  top: calc(50% - 21px);
  left: calc(50% - 21px);
  line-height: 130%;
  font-weight: 800;
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
}
.div1 {
  top: -100px;
  left: 170px;
  width: 70px;
  height: 70px;
  text-align: center;
  font-size: var(--font-size-13xl);
  margin: auto;
}
.illustrationIcon,
.step {
  position: absolute;
  height: 300px;
}
.illustrationIcon {
  top: -720px;
  height: 233px;
  margin: 12.5px;
  overflow: hidden;
}
.step {
  top: 760px;
  left: 0;
  width: 100%;
}
.div4 {
  top: -280px;
  left: 170px;
  width: 75px;
  height: 75px;
  text-align: center;
  font-size: var(--font-size-13xl);
}
.div4,
.step1,
.text2 {
  position: absolute;
}
.step1 {
  top: 800px;
  left: 0;
  width: 100%;
  height: 300px;
}
.text2 {
  top: 260px;
  left: 0px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.step2,
.steps {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  text-align: center;
}
.steps {
  left: 0px;
  height: 780px;
  text-align: left;
  width: 90%;
  background: linear-gradient(297.79deg, #A7048B 10.92%, #A00487 14.91%, #4A025C 61.78%, #150041 94.69%, #000037 110.65%);
  margin: 10px auto;
  left: 10px;
  border-radius: 15px;
  font-size: var(--font-size-xl);
}
.numbers {
  position: relative;
  width: 100%;
  height: 900px;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--white);
  font-family: var(--font-inter);
}

}