/*Computer*/
@media screen and (min-width: 940px) {

.div3Image {
  width: 100%;
  opacity: 0;
  position: absolute;
  z-index: 888888;
}


.div3:focus {
 left: 0;
 width: 475px;
 transition: transform 0.2s ease-in-out;
 transform: translateX(0);
 z-index: 999999;
}

.div3:focus img{
 opacity: 1;
}


.div4:focus {
 left: 0;
 width: 475px;
 transition: transform 0.2s ease-in-out;
 transform: translateX(0);
 z-index: 999999; 
}

.div4:focus img{
 opacity: 1;
}

.div5:focus {
 left: 0;
 width: 475px;
 transition: transform 0.2s ease-in-out;
 transform: translateX(0);
 z-index: 999999;
}

.div5:focus img{
 opacity: 1;
}


.div3:focus,
.div3:focus + .experience {
    background-color: none;
}

.div3, .div4, .div5 {
    transform: translateX(20px);
}

.learningpngParentHidden {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.learningpngParentHidden {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 420px;
}

.ourGroundbreakingApproach {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
}
.revolutionizingEducationRequ {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 560px;
  opacity: 0.8;
}
.div {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.learningpngIcon {
  position: relative;
  height: 113px;
  width: 117px;
  padding: 30px;
  flex-shrink: 0;
  object-fit: cover;
}
.animatedLearningModules {
  align-self: stretch;
  position: relative;
  line-height: 38px;
  text-transform: uppercase;
  font-weight: 800;
}
.weHaveMade {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 30px;
}
.animatedLearningModulesParent,
.learningpngParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.animatedLearningModulesParent {
  width: 417px;
  gap: var(--gap-5xs);
}
.learningpngParent {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 420px;
}
.div2 {
  position: absolute;
  top: 0;
  left: calc(50% - 540px);
  background: linear-gradient(-73.83deg, #b0009c, #ff22e6);
  width: 480px;
  height: 500px;
  color: var(--color-white);
  transition: transform 0.2s ease-in-out;
  transform: translateX(0);
}


.experience {
  position: absolute;
  top: 465px;
  left: 40px;
  line-height: 38px;
  text-transform: uppercase;
  font-weight: 800;
  transform: rotate(-90deg);
  transform-origin: 0 0;
}
.div3,
.div4 {
  position: absolute;
  top: 0;
  width: 200px;
  height: 500px;
}

.div31,
.div42 {
  position: absolute;
  top: 0;
  width: 200px;
  height: 500px;
}

.div53 {
  position: absolute;
  top: 0;
  width: 230px;
  height: 500px;
}

.div3 {
  left: calc(55% - 160px);
  background: linear-gradient(-73.83deg, #44a659, #3cff65);
  z-index: 333333;
}
.div4 {
  left: calc(50% + 90px);
  background: linear-gradient(-73.83deg, #004ce8, #6f9eff);
  z-index: 333333;
}
.div5 {
  left: calc(50% + 290px);
  background: linear-gradient(-73.83deg, #e36400, #ff984e);
  z-index: 333333;
}

.div31 {
  left: calc(55% - 140px);
  background: linear-gradient(-73.83deg, #b0009c, #ff22e6);
  z-index: 222222;
}
.div42 {
  left: calc(50% + 110px);
  background: linear-gradient(-73.83deg, #b0009c, #ff22e6);
  z-index: 222222;
}
.div53 {
  left: calc(50% + 310px);
  background: linear-gradient(-73.83deg, #b0009c, #ff22e6);
  z-index: 222222;
}

.div5,
.div6,
.div7 {
  position: absolute;
  top: 0;
  width: 230px;
  height: 500px;
}
.div6 {
  left: calc(50% + 300px);
  background: linear-gradient(-73.83deg, #232323, #8a8a8a);
}
.div7 {
  left: calc(50% + 420px);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
}
.div1 {
  position: relative;
  border-radius: var(--br-17xl);
  background-color: var(--color-orange);
  width: 1080px;
  height: 500px;
  flex-shrink: 0;
  overflow: hidden;
  text-align: left;
  color: var(--color-gray-100);
  cursor: pointer;

}
.divParent {
  background-color: var(--color-white);
  width: 100%;
  height: 838px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: var(--padding-53xl) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-17xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}


}

/*Mobile*/
@media screen and (max-width: 940px) {

.div3Image {
  height: 105%;
  top: -2%;
  opacity: 0;
  position: absolute;
  z-index: 888888;
  transform: rotate(270deg);
}

.div3:focus {
 left: 0;
 width: 355px;
 transition: transform 0.2s ease-in-out;
 transform: translateX(0);
 z-index: 888888;
}

.div3:focus img{
 opacity: 1;
}


.div4:focus {
 left: 0;
 width: 355px;
 transition: transform 0.2s ease-in-out;
 transform: translateX(0);
 z-index: 888888;
}

.div4:focus img{
 opacity: 1;
}


.div5:focus {
 left: 0;
 width: 355px;
 transition: transform 0.2s ease-in-out;
 transform: translateX(0);
  z-index: 888888;
}

.div5:focus img{
 opacity: 1;
}


.div3, .div4, .div5 {
    transform: translateX(20px);
}

.ourGroundbreakingApproach {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 28px;
}
.revolutionizingEducationRequ {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 611px;
  opacity: 0.8;
  width: 100%;
}
.div {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.learningpngIcon {
  position: relative;
  left: 40px;
  width: 117.88px;
  height: 113px;
  flex-shrink: 0;
  object-fit: cover;
}
.animatedLearningModules {
  align-self: stretch;
  position: relative;
  line-height: 38px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 24px;
  left: 10px;
}
.weHaveMade {
  align-self: stretch;
  position: relative;
  line-height: 30px;
  font-size: 16px;
  left: 10px;
  width: 80%;
}
.animatedLearningModulesParent,
.learningpngParent {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.animatedLearningModulesParent {
  width: 90%;
  margin: auto;
  gap: var(--gap-5xs);
}
.learningpngParent {
  top: 30px;
  width: 420px;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);

}
.div2 {
  position: absolute;
  top: 0;
  left: calc(50% - 450px);
  background: linear-gradient(-73.83deg, #b0009c, #ff22e6);
  width: 480px;
  height: 360px;
  color: var(--color-white);
}
.experience {
  position: absolute;
  top: 320px;
  left: 20px;
  line-height: 38px;
  text-transform: uppercase;
  font-weight: 800;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  font-size: 24px;
}
.div3,
.div4 {
  position: absolute;
  top: 0;
  width: 140px;
  height: 360px;
}

.div31,
.div42 {
  position: absolute;
  top: 0;
  width: 140px;
  height: 360px;
}

.div53 {
  position: absolute;
  top: 0;
  width: 230px;
  height: 360px;
}

.div3 {
  left: calc(50% - 80px);
  background: linear-gradient(-73.83deg, #44a659, #3cff65);
  z-index: 333333;
}
.div4 {
  left: calc(50% + 60px);
  background: linear-gradient(-73.83deg, #004ce8, #6f9eff);
  z-index: 333333;  
}
.div5 {
  left: calc(50% + 180px);
  background: linear-gradient(-73.83deg, #e36400, #ff984e);
  z-index: 333333; 
  border-bottom-right-radius: 20px; 
  border-top-right-radius: 20px;
}

.div31 {
  left: calc(50% - 60px);
  background: linear-gradient(-73.83deg, #b0009c, #ff22e6);
  z-index: 222222;
}
.div42 {
  left: calc(50% + 80px);
  background: linear-gradient(-73.83deg, #b0009c, #ff22e6);
  z-index: 222222;
}
.div53 {
  left: calc(50% + 200px);
  background: linear-gradient(-73.83deg, #b0009c, #ff22e6);
  z-index: 222222;
  height: 360px;
  width: 140px;
  border-bottom-left-radius: 20px; 
  border-top-right-radius: 20px;
  border-radius: 20px;
}


.div5,
.div6,
.div7 {
  position: absolute;
  top: 0;
  width: 140px;
  height: 360px;
}
.div6 {
  left: calc(50% + 300px);
  background: linear-gradient(-73.83deg, #232323, #8a8a8a);
}
.div7 {
  left: calc(50% + 420px);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
}
.div1 {
  margin-top: 180px;
  position: relative;
  border-radius: var(--br-17xl);
  width: 830px;
  height: 360px;
  flex-shrink: 0;
  overflow: hidden;
  text-align: left;
  color: var(--color-gray-100);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.divParent {
  background-color: var(--color-white);
  width: 100%;
  height: 1200px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: var(--padding-53xl) 0;
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-17xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}


}  
