/*Computer*/
@media screen and (min-width: 940px) {

.autoSlider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1200px;
  margin: 50px auto;
}

.sliderContentPara {
  flex-direction: row;
  text-align: left;
  align-self: stretch;
  position: relative;
  line-height: 44px;
  font-size: 28px;
  font-style: italic;
  margin-top: 20px;
  width: 640px;
  margin-left: 35px;
  height: 300px;
}

.sliderContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  align-self: stretch;
  position: relative;
  line-height: 44px;
  font-size: 28px;
  font-style: italic;
}

.sliderContent .content {
  margin-left: 20px;
}

.sliderContent img {

}

.mainImage {
    border-radius: var(--br-13xl);
    width: 391.87px;
    height: 447px;
    object-fit: cover;
    z-index: 0;
}

.sliderContent .buttons {
  display: flex;
}

.sliderContent .buttons button {
  padding: 10px 20px;
  margin: 0 5px;
  font-size: 16px;
  border: none;
  background-color: #333;
  color: #fff;
  cursor: pointer;
      border-radius: 53.33px;
    background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
    flex-direction: row;
    padding: 10.666666984558105px;
    align-items: flex-start;
}

.sliderContent .buttons button:hover {
  background-color: #555;
}

.whatParentsSay {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
}
.div {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
}
.imageIcon,
.shape,
.shapeIcon {
  position: relative;
  flex-shrink: 0;
}
.imageIcon {
  border-radius: var(--br-13xl);
  width: 391.87px;
  height: 447px;
  object-fit: cover;
  z-index: 0;
}
.shape,
.shapeIcon {
  height: 10px;
}
.shape {
  border-radius: var(--br-lgi);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  width: 48px;
}
.shapeIcon {
  width: 10px;
}
.slider1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.chevronIcon {
  position: relative;
  width: 26.67px;
  height: 26.67px;
  flex-shrink: 0;
  overflow: hidden;
}
.prevBtn {
  border-radius: 53.33px;
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  flex-direction: row;
  padding: 10.666666984558105px;
  align-items: flex-start;
}
.imageParent,
.prevBtn,
.sliderBtns {
  display: flex;
  justify-content: flex-start;
}
.sliderBtns {
  margin: 0 !important;
  position: absolute;
  top: 199.17px;
  left: -26.06px;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-331xl);
  z-index: 2;
}
.imageParent {
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: var(--gap-base);
}
.frameChild,
.quotesIcon {
  position: relative;
  flex-shrink: 0;
}
.quotesIcon {
  border-radius: var(--br-5xs);
  width: 52px;
  height: 52px;
  overflow: hidden;
}
.frameChild {
  width: 152px;
  height: 24px;
}
.text {
  align-self: stretch;
  position: relative;
  line-height: 44px;
  opacity: 0.8;
}
.fatherOfAarav,
.text1 {
  position: relative;
  width: 526px;
}
.fatherOfAarav {
  font-size: var(--font-size-25xl);
  line-height: 40px;
  display: flex;
  align-items: center;
}
.text1 {
  font-size: var(--font-size-lg);
  letter-spacing: -0.04em;
  line-height: 28px;
  font-weight: 500;
  display: inline-block;
  opacity: 0.6;
}
.quotesParent,
.textParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.textParent {
  align-self: stretch;
  gap: var(--gap-base);
}
.quotesParent {
  width: 642px;
  flex-shrink: 0;
  gap: var(--gap-5xl);
}
.divParent,
.slider {
  width: 1240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-41xl);
  text-align: left;
  font-size: var(--font-size-9xl);
}
.divParent {
  background-color: var(--white);
  width: 100%;
  flex-direction: column;
  padding: var(--padding-53xl) 0;
  box-sizing: border-box;
  gap: var(--gap-17xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}


}

/*Mobile*/
@media screen and (max-width: 940px) {

.autoSlider {
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.sliderContentPara {
  flex-direction: row;
  text-align: center;
  align-self: stretch;
  position: relative;
  line-height: 44px;
  font-size: 17px;
  font-style: italic;
  margin-top: 20px;
  width: 340px;
  margin: 10px auto;
}

.sliderContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  align-self: stretch;
  position: relative;
  line-height: 44px;
  font-size: 28px;
  font-style: italic;
}

.sliderContent .content {
  margin-left: 20px;
}

.sliderContent img {

}

.mainImage {
    border-radius: var(--br-13xl);
    height: 330px;
    object-fit: cover;
    z-index: 0;
}

.sliderContent .buttons {
  display: flex;
}

.sliderContent .buttons button {
  padding: 10px 20px;
  margin: 0 5px;
  font-size: 16px;
  border: none;
  background-color: #333;
  color: #fff;
  cursor: pointer;
      border-radius: 53.33px;
    background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
    flex-direction: row;
    padding: 10.666666984558105px;
    align-items: flex-start;
}

.sliderContent .buttons button:hover {
  background-color: #555;
}

.whatParentsSay {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 24px;
}
.div {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
}
.imageIcon,
.shape,
.shapeIcon {
  position: relative;
  flex-shrink: 0;
}
.imageIcon {
  border-radius: var(--br-13xl);
  width: 330pxpx;
  height: 360px;
  object-fit: cover;
  z-index: 0;
}
.shape,
.shapeIcon {
  height: 10px;
}
.shape {
  border-radius: var(--br-lgi);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  width: 48px;
}
.shapeIcon {
  width: 10px;
}
.slider1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.chevronIcon {
  position: relative;
  width: 26.67px;
  height: 26.67px;
  flex-shrink: 0;
  overflow: hidden;
}
.prevBtn {
  border-radius: 53.33px;
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  flex-direction: row;
  padding: 10.666666984558105px;
  align-items: flex-start;
}
.imageParent,
.prevBtn,
.sliderBtns {
  display: flex;
  justify-content: flex-start;
}
.sliderBtns {
  margin: 0 !important;
  position: absolute;
  top: 155.17px;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-331xl);
  z-index: 2;
  gap: 260px;
  cursor: pointer;
}
.imageParent {
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: var(--gap-base);
}
.frameChild,
.quotesIcon {
  position: relative;
  flex-shrink: 0;
}
.quotesIcon {
  border-radius: var(--br-5xs);
  width: 52px;
  height: 52px;
  overflow: hidden;
  margin: auto;
  margin-top: 40px;
  display: none;
}
.frameChild {
  width: 152px;
  height: 24px;
  margin: auto;
  top: 20px;
}
.text {
  align-self: stretch;
  position: relative;
  line-height: 44px;
  opacity: 0.8;
  font-size: 17px;
}
.fatherOfAarav,
.text1 {
  position: relative;
  width: 100%;
  text-align: center;
}
.fatherOfAarav {
  font-size: var(--font-size-25xl);
  line-height: 40px;
  align-items: center;
  text-align: center;
  font-size: 26px;
}
.text1 {
  font-size: var(--font-size-lg);
  letter-spacing: -0.04em;
  line-height: 28px;
  font-weight: 500;
  display: inline-block;
  opacity: 0.6;
}
.quotesParent,
.textParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.textParent {
  align-self: stretch;
  gap: var(--gap-base);
  width: 80%;
  margin: auto;
}
.quotesParent {
  width: 100%;
  text-align: center;
  flex-shrink: 0;
  gap: var(--gap-5xl);
}
.divParent,
.slider {
  margin-top: 40px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-41xl);
  text-align: left;
  font-size: var(--font-size-9xl);
}
.divParent {
  background-color: var(--white);
  width: 100%;
  flex-direction: column;
  padding: var(--padding-53xl) 0;
  padding-top: 0px;
  box-sizing: border-box;
  gap: var(--gap-17xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}

}  
