/* Desktop Responsive */
@media only screen and (min-width: 940px) {

.frameIcon,
.maskGroupIcon {
  position: absolute;
  margin: 0 !important;
  overflow: hidden;
}
.maskGroupIcon {
  height: 129.01%;
  width: 131.17%;
  top: 0;
  right: -31.17%;
  bottom: -29.01%;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  z-index: 0;
}
.frameIcon {
  top: calc(50% - 291px);
  left: calc(50% - 756px);
  width: 100%;
  height: 782px;
  flex-shrink: 0;
  opacity: 0.6;
  mix-blend-mode: soft-light;
  z-index: 1;
}
.students {
  position: relative;
  line-height: 23px;
}
.aafterIcon {
  position: relative;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}
.text {
  position: relative;
  line-height: 23px;
  font-family: var(--font-jost);
}
.a,
.ulmenuPrimaryMenu {
  display: flex;
  flex-direction: row;
}
.a {
  padding: var(--padding-5xl) var(--padding-4xs) var(--padding-5xl)
    var(--padding-sm);
  align-items: center;
  justify-content: center;
  gap: 4.11px;
}
.ulmenuPrimaryMenu {
  padding: 0 var(--padding-xs) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.getTheApp {
  position: relative;
  line-height: 36px;
  font-weight: 600;
}
.a1,
.div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.a1 {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  padding: var(--padding-9xs) var(--padding-xl);
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-sm);
}
.div {
  position: absolute;
  top: calc(50% - 35.5px);
  left: 669px;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.frameIcon1 {
  position: absolute;
  top: 12px;
  left: 136px;
  width: 202.36px;
  height: 48px;
  overflow: hidden;
}
.header {
  position: relative;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.07);
  width: 100%;
  height: 71px;
  flex-shrink: 0;
  z-index: 2;
  display: none;
}
.wereChangingThe {
  position: relative;
  line-height: 70px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 742px;
  font-size: 48px;
}
.weFoundThe {
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 32px;
  color: var(--white);
  display: inline-block;
  width: 673px;
  opacity: 0.8;
  padding: 40px;
}
.wereChangingTheWholeGameParent {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-21xl);
}
.teamPana1Icon {
  position: relative;
  height: 380px;
  flex-shrink: 0;
  overflow: hidden;
}
.frameParent {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-17xl);
  z-index: 3;
  text-align: center;
  font-size: var(--font-size-45xl);
  color: var(--color-white);
}
.heroChild,
.heroItem {
  position: absolute;
  margin: 0 !important;
  top: 267px;
  width: 340.06px;
  height: 208.88px;
  float: right;
  right: 0;
  height: 186.31px;
  flex-shrink: 0;
  z-index: 4;
}
.heroItem {
  top: 255px;
  left: 0;
  width: 340.06px;
  height: 208.88px;
  z-index: 5;
}
.hero {
  width: 100%;
  height: 972px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-77xl);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--white);
  font-family: var(--font-inter);
}

}

/* Mobile Responsive */
@media only screen and (max-width: 940px) {

.frameIcon,
.maskGroupIcon {
  position: absolute;
  margin: 0 !important;
  overflow: hidden;
}
.maskGroupIcon {
  height: 90%;
  width: 131.17%;
  top: 0;
  right: -31.17%;
  bottom: -29.01%;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  z-index: 0;
}
.frameIcon {
  top: calc(50% - 291px);
  left: calc(50% - 756px);
  width: 100%;
  height: 782px;
  flex-shrink: 0;
  opacity: 0.6;
  mix-blend-mode: soft-light;
  z-index: 1;
}
.students {
  position: relative;
  line-height: 23px;
}
.aafterIcon {
  position: relative;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}
.text {
  position: relative;
  line-height: 23px;
  font-family: var(--font-jost);
}
.a,
.ulmenuPrimaryMenu {
  display: flex;
  flex-direction: row;
}
.a {
  padding: var(--padding-5xl) var(--padding-4xs) var(--padding-5xl)
    var(--padding-sm);
  align-items: center;
  justify-content: center;
  gap: 4.11px;
}
.ulmenuPrimaryMenu {
  padding: 0 var(--padding-xs) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  display: none;
}
.getTheApp {
  position: relative;
  line-height: 36px;
  font-weight: 600;
}
.a1,
.div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.a1 {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  padding: var(--padding-9xs) var(--padding-xl);
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-sm);
  margin: 10px;
}
.div {
  position: absolute;
  top: calc(50% - 35.5px);
  left: 669px;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.frameIcon1 {
  position: absolute;
  top: 12px;
  left: 40px;
  width: 175px;
  height: 48px;
  overflow: hidden;
}
.header {
    display: none;
    position: relative;
    box-shadow: 0 4px 25px rgb(0 0 0 / 7%);
    width: 100%;
    height: 71px;
    flex-shrink: 0;
    z-index: 2;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--white);
    font-family: var(--font-inter);
}
.wereChangingThe {
  position: relative;
  line-height: 48px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 28px;
}
.weFoundThe {
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 32px;
  color: var(--white);
  display: inline-block;
  width: 100%;
  opacity: 0.8;
  font-size: 16px;
}
.wereChangingTheWholeGameParent {
  width: 100%;
  position: absolute;
  top: 280px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-21xl);
}
.teamPana1Icon {
  position: relative;
  width: 328px;
  flex-shrink: 0;
  overflow: hidden;
}
.frameParent {
  position: absolute;
  top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-17xl);
  z-index: 3;
  text-align: center;
  font-size: var(--font-size-45xl);
  color: var(--color-white);
}
.heroChild,
.heroItem {
  position: absolute;
  margin-top: 480px;
  right: 0;
  width: 72px;
  height: 41px;
  z-index: 4;
}
.heroItem {
  left: 0;
  width: 72px;
  height:41px;
  z-index: 5;
}
.hero {
  height: 950px;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-77xl);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--white);
  font-family: var(--font-inter);
}

} 