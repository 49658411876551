/* Desktop Responsive */
@media only screen and (min-width: 940px) {


.divSliderHead {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  width: 100%;
  margin: 40px 0px;
}

.whatParentsSay {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  text-align: center;
}


.frameIcon,
.maskGroupIcon {
  position: absolute;
  margin: 0 !important;
  overflow: hidden;
}
.maskGroupIcon {
  height: 98.35%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 1.65%;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  z-index: 0;
}
.frameIcon {
  top: calc(50% - 507px);
  left: calc(50% - 756px);
  width: 100%;
  height: 782px;
  flex-shrink: 0;
  opacity: 0.6;
  mix-blend-mode: soft-light;
  z-index: 1;
}
.hero {
  height: 972px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.withGenxture {
  margin: 0;
}
.trustedBy1,
.withGenxtureYourContainer {
  position: relative;
  line-height: 56px;
  font-weight: 600;
}
.trustedBy1 {
  font-size: var(--font-size-3xl);
  line-height: 31.43px;
}
.playStoresvgIcon {
  position: relative;
  border-radius: var(--br-3xs);
  width: 145px;
  height: 50px;
  flex-shrink: 0;
}
.playStoresvgParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 23px;
}
.div,
.divWrapper,
.home {
  display: flex;
  flex-direction: column;
}
.div {
  border-radius: var(--br-13xl);
  background: linear-gradient(-73.83deg, #ffd600, #ffe766);
  width: 822px;
  overflow: hidden;
  padding: 32px 132px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 34px;
}
.divWrapper,
.home {
  background-color: var(--white);
}
.divWrapper {
  width: 100%;
  padding: var(--padding-53xl) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.home {
  position: relative;
  width: 100%;
  margin: auto;
  /*align-items: flex-start;*/
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}

}

/* Mobile Responsive */
@media only screen and (max-width: 940px) {

body {
  overflow-x: hidden;
}

.text {
    align-self: stretch;
    position: relative;
    line-height: 44px;
    opacity: 0.8;
    font-style: italic;
}

.divSliderHead {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.whatParentsSay {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 22px;
  margin: 40px auto;
}

.frameIcon,
.maskGroupIcon {
  position: absolute;
  margin: 0 !important;
  overflow: hidden;
}
.maskGroupIcon {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 1.65%;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  z-index: 0;
}
.frameIcon {
  display: none;
  top: calc(50% - 507px);
  left: calc(50% - 756px);
  width: 100%;
  height: 782px;
  flex-shrink: 0;
  opacity: 0.6;
  mix-blend-mode: soft-light;
  z-index: 1;
}
.hero {
  width: 100%;
  height: 900px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.withGenxture {
  margin: 0;
}
.trustedBy1,
.withGenxtureYourContainer {
  position: relative;
  line-height: 45px;
  font-weight: 600;
  font-size: 30px;
}
.trustedBy1 {
  font-size: var(--font-size-3xl);
  line-height: 31.43px;
}
.playStoresvgIcon {
  position: relative;
  border-radius: var(--br-3xs);
  width: 145px;
  height: 50px;
  flex-shrink: 0;
}
.playStoresvgParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 23px;
}
.div,
.divWrapper,
.home {
  display: flex;
  flex-direction: column;
}
.div {
  border-radius: var(--br-13xl);
  background: linear-gradient(-73.83deg, #ffd600, #ffe766);
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 20px;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
}
.divWrapper,
.home {
  background-color: var(--white);
}
.divWrapper {
  width: 90%;
  margin: auto;
  padding: var(--padding-53xl) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.home {
  position: relative;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}

}  
