/* Desktop Responsive */
@media only screen and (min-width: 940px) {

.div5 Link {
  text-decoration: none;
  color: #f2f2f2;
}

.connectWithUs {
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 17.14px;
}
.lpFbpngIcon,
.lpInpngIcon,
.lpTwpngIcon {
  position: absolute;
  top: calc(50% - 22px);
  left: 0;
  width: 34px;
  height: 34px;
  object-fit: cover;
}
.lpInpngIcon,
.lpTwpngIcon {
  left: 46.7px;
}
.lpTwpngIcon {
  left: 93.41px;
}
.div3 {
  position: relative;
  width: 420px;
  height: 44px;
  flex-shrink: 0;
}
.genxtureStandsFor {
  position: relative;
  line-height: 20px;
  opacity: 0.6;
  color: #ffffff;
  width: 360px;
  text-decoration: none;
}
.genxtureStandsForEducationParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.copyright2023 {
  position: relative;
  line-height: 20px;
}
.div2 {
  position: absolute;
  top: 0;
  left: calc(50% - 450px);
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-mini);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  font-size: var(--font-size-base);
}
.company {
  top: -1px;
  left: 15px;
  line-height: 20px;
}
.company,
.div4,
.ul {
  position: absolute;
}
.ul {
  top: 30px;
  left: 15px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xs);
  font-size: var(--font-size-base);
}
.div4 {
  width: calc(100% - 600.02px);
  top: 0;
  right: 150.02px;
  left: 450px;
  height: 240px;
}
.wereHiring {
  position: relative;
  line-height: 22.86px;
  background: linear-gradient(-73.83deg, #ffd600, #ffe766);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ul1,
.ulWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.ul1 {
  flex-direction: column;
  gap: var(--gap-7xs);
}
.ulWrapper {
  top: 19.92px;
  left: 5px;
  flex-direction: row;
  padding: var(--padding-3xs);
  font-size: var(--font-size-base);
}
.div1,
.div5,
.ulWrapper {
  position: absolute;
}
.div5 {
  width: calc(100% - 750.02px);
  top: 0;
  right: 0.03px;
  left: 749.98px;
  height: 217.84px;
}
.div1 {
  top: 60px;
  left: 306px;
  width: 900px;
  height: 1px;
}
.div {
  position: relative;
  background-color: var(--text);
  width: 100%;
  height: 320px;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--white);
  font-family: var(--font-lato);
}

}

/* Mobile Responsive */
@media only screen and (max-width: 940px) {

.connectWithUs {
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 17.14px;
}
.lpFbpngIcon,
.lpInpngIcon,
.lpTwpngIcon {
  position: absolute;
  top: calc(50% - 22px);
  left: 0;
  width: 34px;
  height: 34px;
  object-fit: cover;
}
.lpInpngIcon,
.lpTwpngIcon {
  left: 46.7px;
}
.lpTwpngIcon {
  left: 93.41px;
}
.div3 {
  position: relative;
  width: 420px;
  height: 44px;
  flex-shrink: 0;
}
.genxtureStandsFor {
  position: relative;
  line-height: 20px;
  opacity: 0.6;
  color: #ffffff;
  text-decoration: none;
}
.genxtureStandsForEducationParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.copyright2023 {
  position: relative;
  line-height: 20px;
}
.div2 {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-mini);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  font-size: var(--font-size-base);
}
.company {
  top: -1px;
  left: 15px;
  line-height: 20px;
}
.company,
.div4,
.ul {
  position: absolute;
}
.ul {
  top: 30px;
  left: 15px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xs);
  font-size: var(--font-size-base);
}
.div4 {
  width: 100%;
  top: 300px;
  height: 240px;
}
.wereHiring {
  position: relative;
  line-height: 22.86px;
  background: linear-gradient(-73.83deg, #ffd600, #ffe766);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ul1,
.ulWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.ul1 {
  flex-direction: column;
  gap: var(--gap-7xs);
}
.ulWrapper {
  top: 19.92px;
  left: 5px;
  flex-direction: row;
  padding: var(--padding-3xs);
  font-size: var(--font-size-base);
}
.div1,
.div5,
.ulWrapper {
  position: absolute;
}
.div5 {
  width: 100%;
  top: 480px;
  height: 217.84px;
}
.div1 {
  left: 40px;
  top: 60px;
  width: 100%;
  height: 1px;
}
.div {
  position: relative;
  background-color: var(--text);
  width: 100%;
  height: 760px;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--white);
  font-family: var(--font-lato);
}

}  