/*Computer*/
@media screen and (min-width: 940px) {

.boxcontainer {
	height: 420px;
	width: 40%;
	background-color: purple;
	margin: 40px auto;
	border-radius: 30px;
	text-align: left;
	padding: 20px;
}

.boxcontainer form{
   display: block;
}

.boxcontainer input[type="text"]{
   display: block;
   width: 443px;
   padding: 12px;
   margin: 20px auto;
   border-radius: 8px;
   border: none;
}

.boxcontainer input[type="submit"]{
   display: block;
   width: 140px;
   padding: 10px;
   margin: 15px auto;
   border-radius: 30px;
   cursor: pointer;
   background: linear-gradient(286.17deg, #66A5FA 0%, #723AC5 100%);
   border: none;
   color: #f2f2df;
   font-size: 18px;
}

}


/*Mobile*/
@media screen and (max-width: 940px) {

.boxcontainer {
	height: 420px;
	width: 80%;
   background: linear-gradient(297.79deg, #A7048B 10.92%, #A00487 14.91%, #4A025C 61.78%, #150041 94.69%, #000037 110.65%);
	margin: 40px auto;
	border-radius: 30px;
	text-align: left;
	padding: 10px;
}

.boxcontainer form{
   display: block;
}

.boxcontainer input[type="text"]{
   display: block;
   width: 250px;
   padding: 12px;
   margin: 20px auto;
   border-radius: 8px;
   border: none;
}

.boxcontainer input[type="submit"]{
   display: block;
   width: 140px;
   padding: 10px;
   margin: 15px auto;
   border-radius: 30px;
   cursor: pointer;
   background: linear-gradient(286.17deg, #66A5FA 0%, #723AC5 100%);
   border: none;
   color: #f2f2df;
   font-size: 18px;
}

}