

/*Computer*/
@media screen and (min-width: 940px) {

.division1 {
	height: 510px;
	width: 100%;
	border-radius: 30px;
	text-align: left;
}


.imgdata1 {
	width: 100%;
}

.imgdata2 {
	width: 100%;
	display: none;
}

}



/*Mobile*/
@media screen and (max-width: 940px) {


.division1 {
	height: 1720px;
	width: 100%;
	border-radius: 30px;
	text-align: left;
}


.imgdata1 {
	width: 100%;
	display: none;
}

.imgdata2 {
	width: 100%;
}

}