/* Desktop Responsive */
@media only screen and (min-width: 940px) {

.a:active {
    border-radius: var(--br-11xl);
    background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
    width: 250px;
    padding: var(--padding-3xs) var(--padding-17xl);
    align-items: center;
    justify-content: center;
    color: var(--white);
}

.a:focus {
    border-radius: var(--br-11xl);
    background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
    width: 250px;
    padding: var(--padding-3xs) var(--padding-17xl);
    align-items: center;
    justify-content: center;
    color: var(--white);
}
.a_focus {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  width: 250px;
  padding: var(--padding-3xs) var(--padding-17xl);
  align-items: center;
  justify-content: center;
  color: var(--white);
  border-radius: var(--br-11xl);
  width: 170px;
  align-items: center;
  justify-content: center;
}

.a1:focus {
    border-radius: var(--br-11xl);
    background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
    width: 250px;
    padding: var(--padding-3xs) var(--padding-17xl);
    align-items: center;
    justify-content: center;
    color: var(--white);
}
.a2:focus {
     border-radius: var(--br-11xl);
    background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
    width: 250px;
    padding: var(--padding-3xs) var(--padding-17xl);
    align-items: center;
    justify-content: center;
    color: var(--white);
}

.valuesThatDefines1 {
  display: block;
}

.valuesThatDefines2 {
  display: block;
}

.unionIcon1 {
  position: relative;
  width: 100%;
  height: 1418px;
}

.unionIcon2 {
  display: none;
}

.theGenxtureEndeavor {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  /*width: 551px;*/
}
.valueThatDefines {
  position: relative;
  line-height: 25px;
  font-weight: 500;
  display: flex;
}
.a {
  border-radius: var(--br-11xl);
  width: 250px;
  padding: var(--padding-3xs) var(--padding-17xl);
  align-items: center;
  justify-content: center;
  color: black;
}
.a,
.a1,
.tabs {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;
}
.a1 {
  border-radius: var(--br-11xl);
  width: 250px;
  padding: var(--padding-3xs) var(--padding-17xl);
  align-items: center;
  justify-content: center;
}
.tabs {
  border-radius: var(--br-42xl);
  background-color: var(--white);
  height: 61px;
  padding: var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-base);
  color: var(--text);
  cursor: pointer;
}
.divChild {
  position: relative;
  width: 75px;
  height: 75px;
  flex-shrink: 0;
  object-fit: cover;
}
.curiosity {
  position: relative;
  line-height: 45px;
  text-transform: uppercase;
  font-weight: 800;
}
.buildTrustingRelationships {
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 336.47px;
}
.div,
.div1 {
  border-radius: var(--br-xl);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 400px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xl);
  padding: 30px;
}
.div {
  background: linear-gradient(-73.83deg, #b0009c, #ff22e6);
}
.div1 {
  align-self: stretch;
  background: linear-gradient(-73.83deg, #e36400, #ff984e);
}
.buildTrustingRelationships2 {
  position: relative;
  line-height: 28px;
  font-size: 20px;
  display: flex;
  align-items: center;
  width: 336.47px;
  height: 85px;
  flex-shrink: 0;
}
.div2 {
  align-self: stretch;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 400px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-5xl);
  padding: 12.5px;
}
.div2,
.div3,
.divParent {
  align-items: flex-start;
}
.divParent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: left;
    align-items: center;
    display: flex;
    margin-top: 25px;
    justify-content: center;

}
.div3 {
  align-self: stretch;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #232323, #8a8a8a);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 400px;
  flex-shrink: 0;
  gap: var(--gap-5xl);
  padding: 30px;
}
.div3,
.div4,
.ourCulture {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
}
.div4 {
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #44a659, #3cff65);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 400px;
  flex-shrink: 0;
  align-items: flex-start;
  padding: 30px;
  gap: var(--gap-5xl);
}
.ourCulture {
  position: absolute;
  top: 174px;
  left: 0;
  width: 100%;
  padding: var(--padding-53xl) 0;
  align-items: center;
  gap: var(--gap-17xl);
}
.numbers {
  position: relative;
  width: 100%;
  height: 1418px;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--white);
  font-family: var(--font-inter);
}

}


/* Mobile Responsive */
@media only screen and (max-width: 940px) {

.a:active {
    border-radius: var(--br-11xl);
    background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
    padding: var(--padding-3xs) var(--padding-17xl);
    align-items: center;
    justify-content: center;
    color: var(--white);
    width: 155px;
}
.a_focus {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  padding: var(--padding-3xs) var(--padding-17xl);
  align-items: center;
  justify-content: center;
  color: var(--white);
  border-radius: var(--br-11xl);
  width: 155px;
  align-items: center;
  justify-content: center;
}
.a:focus {
    border-radius: var(--br-11xl);
    background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
    padding: var(--padding-3xs) var(--padding-17xl);
    align-items: center;
    justify-content: center;
    color: var(--white);
    width: 155px;
}

.a1:focus {
    border-radius: var(--br-11xl);
    background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
    padding: var(--padding-3xs) var(--padding-17xl);
    align-items: center;
    justify-content: center;
    color: var(--white);
    width: 155px;
}
.a2:focus {
     border-radius: var(--br-11xl);
    background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
    padding: var(--padding-3xs) var(--padding-17xl);
    align-items: center;
    justify-content: center;
    color: var(--white);
    width: 155px;
}

.valuesThatDefines1 {
  display: block;
}

.valuesThatDefines2 {
  display: none;
}

.unionIcon2 {
  position: relative;
  width: 100%;
  height: auto;
  left: 0;
}

.unionIcon1 {
 display: none;
}


.theGenxtureEndeavor {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 24px;
}
.valueThatDefines {
  position: relative;
  line-height: 25px;
  font-weight: 500;
  font-size: 12px;
}
.a {
  border-radius: var(--br-11xl);
  width: 250px;
  padding: var(--padding-3xs) var(--padding-17xl);
  align-items: center;
  justify-content: center;
  color: black;
}
.a,
.a1,
.tabs {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 110px;
}
.a1 {
  border-radius: var(--br-11xl);
  align-items: center;
  justify-content: center;
  padding: 10px 2.5px;
  font-size: 12px;
}
.tabs {
  width: 94%;
  border-radius: var(--br-42xl);
  background-color: var(--white);
  height: 61px;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-base);
  color: var(--text);
  cursor: pointer;
}
.divChild {
  position: relative;
  width: 75px;
  height: 75px;
  flex-shrink: 0;
  object-fit: cover;
}
.curiosity {
  position: relative;
  line-height: 45px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 28px;
}
.buildTrustingRelationships {
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 28px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.div,
.div1 {
  border-radius: var(--br-xl);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 360px;
  /*height: 300px;*/
  margin: 20px auto;
  padding: 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xl);
}
.div {
  background: linear-gradient(-73.83deg, #b0009c, #ff22e6);
}
.div1 {
  align-self: stretch;
  background: linear-gradient(-73.83deg, #e36400, #ff984e);
}
.buildTrustingRelationships2 {
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 28px;
  display: flex;
  align-items: center;
  height: 85px;
  flex-shrink: 0;
  font-size: 16px;
}
.div2 {
  align-self: stretch;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-5xl);
  padding: 20px;
  width: 360px;
  height: 300px;
}
.div2,
.div3,
.divParent {
  align-items: flex-start;
}
.divParent {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  margin-top: 20px;
}
.div3 {
  align-self: stretch;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #44a659, #3cff65);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  height: 300px;
  width: 360px;
  flex-shrink: 0;
  padding: 20px; 
  gap: var(--gap-5xl);
  margin: 0px auto 20px auto;
}
.div3,
.div4,
.ourCulture {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
}
.div4 {
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #232323, #8a8a8a);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 360px;
  height: 300px;
  flex-shrink: 0;
  padding: 20px;
  align-items: flex-start;
  gap: var(--gap-5xl);
}
.ourCulture {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  padding: var(--padding-53xl) 0;
  align-items: center;
  gap: var(--gap-17xl);
}
.numbers {
  position: relative;
  width: 100%;
  height: 2230px;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--white);
  font-family: var(--font-inter);
}

}  
