/*Computer*/
@media screen and (min-width: 940px) {



.division1 {
	height: 320px;
	width: 80%;
	background: linear-gradient(297.79deg, #A7048B 10.92%, #A00487 14.91%, #4A025C 61.78%, #150041 94.69%, #000037 110.65%);
	margin: 10px auto;
	border-radius: 30px;
	text-align: left;
}

.division1Text {
	padding: 30px;
	font-size: 20px;
	font-style: italic;
	color: #ffffff;
	line-height: 1.3;
}

.division1Title {
	padding: 10px 30px;
	font-size: 34px;
	color: #ffffff;
}

}



/*Mobile*/
@media screen and (max-width: 940px) {



.division1 {
	width: 90%;
	background: linear-gradient(297.79deg, #A7048B 10.92%, #A00487 14.91%, #4A025C 61.78%, #150041 94.69%, #000037 110.65%);
	margin: 10px auto;
	border-radius: 30px;
	text-align: left;
}

.division1Text {
	padding: 30px;
	font-size: 20px;
	font-style: italic;
	color: #ffffff;
	line-height: 1.3;
}

.division1Title {
	padding: 10px 30px;
	font-size: 34px;
	color: #ffffff;
}


}	
