/*Computer*/
@media screen and (min-width: 940px) {


.whatParentsSay {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
}
.div {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
}
.imageIcon,
.shape,
.shapeIcon {
  position: relative;
  flex-shrink: 0;
}
.imageIcon {
  border-radius: var(--br-13xl);
  height: 441px;
  object-fit: cover;
  z-index: 0;
}
.shape,
.shapeIcon {
  height: 10px;
}
.shape {
  border-radius: var(--br-lgi);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  width: 48px;
}
.shapeIcon {
  width: 10px;
}
.slider1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
  margin-top: 40px;
  margin-left: 50%;
}
.chevronIcon {
  position: relative;
  width: 26.67px;
  height: 26.67px;
  flex-shrink: 0;
  overflow: hidden;
}
.prevBtn {
  border-radius: 53.33px;
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  flex-direction: row;
  padding: 10.666666984558105px;
  align-items: flex-start;
}
.imageParent,
.prevBtn,
.sliderBtns {
  display: flex;
  justify-content: flex-start;
}
.sliderBtns {
  margin: 0 !important;
  position: absolute;
  top: -165.17px;
  left: 600.94px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  z-index: 2;
}
.imageParent {
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: var(--gap-base);
}
.frameChild,
.quotesIcon {
  position: relative;
  flex-shrink: 0;
}
.quotesIcon {
  border-radius: var(--br-5xs);
  width: 52px;
  height: 52px;
  overflow: hidden;
}
.frameChild {
  width: 152px;
  height: 24px;
}
.text {
  align-self: stretch;
  position: relative;
  line-height: 44px;
  opacity: 0.8;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
}
.fatherOfAarav,
.text1 {
  position: relative;
  width: 526px;
}
.fatherOfAarav {
  line-height: 40px;
  display: flex;
  align-items: center;
  font-size: 18px;
}
.text1 {
  font-size: var(--font-size-lg);
  letter-spacing: -0.04em;
  line-height: 28px;
  font-weight: 500;
  display: inline-block;
  opacity: 0.6;
}
.quotesParent,
.textParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.textParent {
  align-self: stretch;
  gap: var(--gap-base);
}
.quotesParent {
  width: 642px;
  flex-shrink: 0;
  gap: var(--gap-5xl);
  padding: 20px;
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
}
.divParent,
.slider {
  width: 1240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-41xl);
  text-align: left;
  font-size: var(--font-size-9xl);
}
.divParent {
  background-color: var(--white);
  width: 100%;
  flex-direction: column;
  padding: var(--padding-53xl) 0;
  box-sizing: border-box;
  gap: var(--gap-17xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}


}

/*Mobile*/
@media screen and (max-width: 940px) {


.whatParentsSay {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 24px;
}
.div {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
}
.imageIcon,
.shape,
.shapeIcon {
  position: relative;
  flex-shrink: 0;
}
.imageIcon {
  border-radius: var(--br-13xl);
  width: 330pxpx;
  height: 360px;
  object-fit: cover;
  z-index: 0;
}
.shape,
.shapeIcon {
  height: 10px;
}
.shape {
  border-radius: var(--br-lgi);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  width: 48px;
}
.shapeIcon {
  width: 10px;
}
.slider1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.chevronIcon {
  position: relative;
  width: 26.67px;
  height: 26.67px;
  flex-shrink: 0;
  overflow: hidden;
}
.prevBtn {
  border-radius: 53.33px;
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  flex-direction: row;
  padding: 10.666666984558105px;
  align-items: flex-start;
}
.imageParent,
.prevBtn,
.sliderBtns {
  display: flex;
  justify-content: flex-start;
}
.sliderBtns {
  margin: 0 !important;
  position: absolute;
  top: 155.17px;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-331xl);
  z-index: 2;
  gap: 260px;
  cursor: pointer;
}
.imageParent {
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: var(--gap-base);
}
.frameChild,
.quotesIcon {
  position: relative;
  flex-shrink: 0;
}
.quotesIcon {
  border-radius: var(--br-5xs);
  width: 52px;
  height: 52px;
  overflow: hidden;
  margin: auto;
  margin-top: 40px;
  display: none;
}
.frameChild {
  width: 152px;
  height: 24px;
  margin: auto;
  top: 20px;
}
.text {
  align-self: stretch;
  position: relative;
  line-height: 44px;
  opacity: 0.8;
  font-size: 17px;
}
.fatherOfAarav,
.text1 {
  position: relative;
  width: 100%;
  text-align: center;
}
.fatherOfAarav {
  font-size: var(--font-size-25xl);
  line-height: 40px;
  align-items: center;
  text-align: center;
  font-size: 26px;
}
.text1 {
  font-size: var(--font-size-lg);
  letter-spacing: -0.04em;
  line-height: 28px;
  font-weight: 500;
  display: inline-block;
  opacity: 0.6;
}
.quotesParent,
.textParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.textParent {
  align-self: stretch;
  gap: var(--gap-base);
  width: 80%;
  margin: auto;
}
.quotesParent {
  width: 100%;
  text-align: center;
  flex-shrink: 0;
  gap: var(--gap-5xl);
}
.divParent,
.slider {
  margin-top: 40px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-41xl);
  text-align: left;
  font-size: var(--font-size-9xl);
}
.divParent {
  background-color: var(--white);
  width: 100%;
  flex-direction: column;
  padding: var(--padding-53xl) 0;
  padding-top: 0px;
  box-sizing: border-box;
  gap: var(--gap-17xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}

}  
