/* Desktop Responsive */
@media only screen and (min-width: 940px) {

  .students {
    position: relative;
    line-height: 23px;
  }

  .aafterIcon {
    position: relative;
    width: 12px;
    height: 12px;
    flex-shrink: 0;
  }

  .text {
    position: relative;
    line-height: 23px;
    font-family: var(--font-jost);
  }

  .a,
  .ulmenuPrimaryMenu1 {
    display: flex;
    flex-direction: row;
  }

  .ulmenuPrimaryMenu1 li {
    display: block;
    position: relative;
  }

  .ulmenuPrimaryMenu1 ul {
    position: absolute;
    display: none;
    padding: 0;
    left: 0;
    margin: 0;
  }

  .ulmenuPrimaryMenu1 li:hover ul {
    display: block;
    background-color: #8d1b72;
    width: max-content;
  }

  .ulmenuPrimaryMenu1 li:hover ul li {
    border-bottom: 1px solid #f075cf;
   
  }
  .ulmenuPrimaryMenu1 li:hover ul li a div{
    font-size: 94% !important;
  }

  .a {
    padding: var(--padding-5xl) var(--padding-4xs) var(--padding-5xl) var(--padding-sm);
    align-items: center;
    justify-content: center;
    gap: 4.11px;
  }

  .ulmenuPrimaryMenu1 {
    padding: 0 var(--padding-xs) 0 0;
    align-items: flex-start;
    justify-content: flex-start;

  }

  .getTheApp {
    position: relative;
    line-height: 36px;
    font-weight: 600;
  }

  .a1 {
    width: 90px;
    border-radius: var(--br-11xl);
    background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-xl);
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--font-size-sm);
    cursor: pointer;
    border: 1px solid #ffffff;
  }

  .div,
  .frameIcon {
    position: absolute;
  }

  .div {
    top: calc(50% - 35.5px);
    right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xl);
  }

  .frameIcon {
    top: 12px;
    left: 136px;
    width: 202.36px;
    height: 48px;
    overflow: hidden;
  }

  .header {
    position: absolute;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.07);
    width: 100%;
    height: 71px;
    flex-shrink: 0;
    z-index: 999999;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--white);
    font-family: var(--font-inter);
  }

  .loginMenu {}

  .hamMenu {
    display: none;
  }

}


/* Mobile Responsive */
@media only screen and (max-width: 940px) {
  .hamMenu {}

  .loginMenu {
    display: none;
  }

  .students {
    position: relative;
    line-height: 23px;
  }

  .aafterIcon {
    position: relative;
    width: 12px;
    height: 12px;
    flex-shrink: 0;
  }

  .text {
    position: relative;
    line-height: 23px;
    font-family: var(--font-jost);
  }

  .a,
  .ulmenuPrimaryMenu {
    display: flex;
    flex-direction: row;
  }

  .a {
    padding: var(--padding-5xl) var(--padding-4xs) var(--padding-5xl) var(--padding-sm);
    align-items: center;
    justify-content: center;
    gap: 4.11px;
  }

  .ulmenuPrimaryMenu1 {
    display: none;
  }

  .ulmenuPrimaryMenu {
    /*display: none;*/
    padding: 0;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
    display: list-item;
    background: linear-gradient(-73.83deg, #1570e9, #723ac5);
    transition: "transform 0.3s ease-in-out";
  }

  .ulmenuPrimaryMenu li {
    display: block;
    position: relative;
  }

  .ulmenuPrimaryMenu ul {
    position: absolute;
    display: none;
    padding: 0;
    left: 0;
    margin: 0;
  }

  .ulmenuPrimaryMenu li:hover ul {
    display: block;
    position: relative;
    background: linear-gradient(-73.83deg, #3559ff, #5b10cd);
    
  }

  .ulmenuPrimaryMenu li:hover ul li {
    border-bottom: 1px solid #9fa8f2;
  }
  .ulmenuPrimaryMenu li:hover ul li a div{
    padding-left:13px;
    font-size: 94% !important;
  }

  .getTheApp {
    position: relative;
    line-height: 36px;
    font-weight: 600;
  }

  .a1 {
    border-radius: var(--br-11xl);
    background: linear-gradient(-73.83deg, #66a5fa, #5800db);
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-xl);
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--font-size-sm);
    margin: 10px 0px 10px 10px;
    cursor: pointer;

    width: 90px;
  }

  .div,
  .frameIcon {
    position: absolute;
  }

  .div {
    top: calc(50% - 35.5px);
    left: 0;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: flex-start;
    gap: var(--gap-xl);
    float: right;
    z-index: 10000000;

    height: 100vh;
  }

  .frameIcon {
    top: 12px;
    left: 16px;
    height: 38px;
    overflow: hidden;
  }

  .header {
    position: absolute;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.07);
    width: 100%;
    height: 71px;
    flex-shrink: 0;
    z-index: 2;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--white);
    font-family: var(--font-inter);
    z-index: 10;
  }


}