@media only screen and (min-width: 940px) {
.home {
    position: relative;
    width: 100%;
    margin: auto;
    /*align-items: flex-start;*/
    justify-content: flex-start;
    text-align: center;
    font-size: var(--font-size-21xl);
    color: var(--text);
    font-family: var(--font-inter);
    background: #fafafa;
  }
}

@media only screen and (max-width: 940px) {
  .home {
    background: #fafafa;
  }}