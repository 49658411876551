/* Desktop Responsive */

@media only screen and (min-width: 940px) {

/*
.section2:focus + .theCurrentEconomic {
  display: block;
}
*/

.thingsYourChild {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
}
.knowWhatMajor {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 611px;
  opacity: 0.8;
}
.div {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.soilTestingwebpIcon {
  position: relative;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  object-fit: cover;
}
.lackOfQuality {
  position: relative;
  line-height: 24.48px;
  font-weight: 600;
}
.svgIcon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
}

.svgIcon3 {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}

.svgIcon03 {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
}

.svgIconRotate {
  position: relative;
  width: 19px;
  height: 56px;
  flex-shrink: 0;
  overflow: hidden;
}

.header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.theCurrentEconomic1, .theCurrentEconomic2, .theCurrentEconomic3, .theCurrentEconomic4, .theCurrentEconomic5 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24.96px;
  opacity: 0.8;
  padding-left: 80px;
  animation-name: example;
  animation-duration: 3s;  
  animation-delay: 2s;
  animation-fill-mode: both;
  transition: all .5s ease-in-out;
}


.section3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.header1,
.section2 {
  display: flex;
  flex-direction: row;
}
.section2 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  padding: var(--padding-6xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  cursor: pointer;
}


.header1 {
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.section1,
.section4 {
  display: flex;
  justify-content: flex-start;
}
.section4 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--color-whitesmoke-100);
  flex-direction: row;
  padding: var(--padding-6xl);
  align-items: center;
  gap: var(--gap-base);
  cursor: pointer;
}
.section1 {
  width: 676px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xl);
}
.sectionChild {
  position: absolute;
  width: 34%;
  height: 487.56px;
  flex-shrink: 0;
  margin-top: 220px;
  float: right;
  margin-left: 50%;
}
.divParent,
.section {
  width: 82%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: var(--font-size-xl);
}
.divParent {
  background-color: var(--color-white);
  width: 100%;
  flex-direction: column;
  padding: 0 0 var(--padding-53xl);
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-17xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}

}

/* Mobile Responsive */
@media only screen and (max-width: 940px) {

.thingsYourChild {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 24px;
  padding: 10px;
}
.knowWhatMajor {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 100%;
  opacity: 0.8;
  padding: 10px 40px;
  font-size: 14px;
}
.div {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.soilTestingwebpIcon {
  position: relative;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  object-fit: cover;
}
.lackOfQuality {
  position: relative;
  line-height: 24.48px;
  font-weight: 600;
}
.svgIcon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
}
.header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.theCurrentEconomic {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24.96px;
  opacity: 0.8;
}
.section3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.header1,
.section2 {
  display: flex;
  flex-direction: row;
}
.section2 {
  align-self: stretch;
  border-radius: var(--br-xs);
  overflow: hidden;
  padding: var(--padding-6xl);
  background-color: var(--color-whitesmoke-100);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.header1 {
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.section1,
.section4 {
  display: flex;
  justify-content: flex-start;
}
.section4 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--color-whitesmoke-100);
  flex-direction: row;
  padding: var(--padding-6xl);
  align-items: center;
  gap: var(--gap-base);
  cursor: pointer;
}
.section1 {
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xl);
  margin: 10px;
}
.sectionChild {
  position: relative;
  width: 515.36px;
  height: 487.56px;
  flex-shrink: 0;
  display: none;
}
.divParent,
.section {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: var(--font-size-xl);
}
.divParent {
  background-color: var(--color-white);
  width: 100%;
  flex-direction: column;
  padding: 0 0 var(--padding-53xl);
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-17xl);
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--text);
  font-family: var(--font-inter);
}

}  