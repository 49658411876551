/*Computer*/
@media screen and (min-width: 940px) {

.iconLms {
width: 40.19px;
height: 40.19px;
position: relative;
top: 5px;
left: -25px;
}

.LmsContent6txt {

}

.threeboxes:nth-child(1) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 160px;
}

.threeboxes:nth-child(2) {
  top: 200px;
}

.threeboxes:nth-child(3) {
  top: 240px;
}

.threeboxesContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 50px;
}

.threeboxesContainerBox {
   width: 310px; background-color: #ffffff; border-radius: 20px;
   padding: 30px 25px 30px 50px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 18px;
    color: black;
}

.unionIcon1 {
  position: relative;
  width: 100%;
  height: 952px;
}

.unionIcon2 {
    position: relative;
  width: 100%;
  height: 952px;
  display: none;
}

.whyGenxture {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
}
.genxtureFocusesOn {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 611px;
  opacity: 0.8;
}
.connect,
.div {
  position: absolute;
}
.div {
  top: 246px;
  left: 147px;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.connect {
  top: 90px;
  left: 35px;
  line-height: 45px;
  text-transform: uppercase;
  font-weight: 800;
}
.buildTrustingRelationships {
  position: absolute;
  top: 154px;
  left: 35px;
  font-size: var(--font-size-xl);
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 336.47px;
  height: 85px;
}
.div3 {
  height: calc(100% + 251px);
  width: calc(100% - 314.99px);
  top: -142px;
  left: 335.19px;
  font-size: var(--font-size-348xl);
  line-height: 277px;
  font-weight: 600;
  color: var(--color-gray-200);
  display: flex;
  align-items: center;
}
.div2,
.div3,
.div5 {
  position: absolute;
}
.div2 {
  width: calc(100% - 628px);
  top: 30px;
  right: 598px;
  left: 30px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #004ce8, #6f9eff);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  height: 280px;
  overflow: hidden;
  font-size: var(--font-size-21xl);
  color: var(--white);
}
.div5 {
  height: calc(100% + 251px);
  width: calc(100% + 68.97px);
  top: -142px;
  left: 11.23px;
  line-height: 277px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.div4 {
  top: 30px;
  left: 502px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #b0009c, #ff22e6);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
  overflow: hidden;
}
.div4,
.div6,
.div7 {
  position: absolute;
}
.div7 {
  height: calc(100% + 251px);
  width: calc(100% + 56.12px);
  top: -142px;
  left: 24.08px;
  line-height: 277px;
  font-weight: 800;
  display: flex;
  align-items: center;
}
.div6 {
  top: 30px;
  left: 644px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #e36400, #ff984e);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
  overflow: hidden;
}
.div9 {
  height: calc(100% + 251px);
  width: calc(100% + 84.37px);
  top: -142px;
  left: -4.17px;
  line-height: 277px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.div11,
.div8,
.div9 {
  position: absolute;
}
.div8 {
  top: 30px;
  left: 786px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #ffd600, #ffe766);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
  overflow: hidden;
}
.div11 {
  height: calc(100% + 251px);
  width: calc(100% + 64.92px);
  top: -142px;
  left: 15.28px;
  line-height: 277px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.div1,
.div10 {
  position: absolute;
  border-radius: var(--br-xl);
  overflow: hidden;
}
.div10 {
  top: 30px;
  left: 928px;
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
}
.div1 {
  top: 408px;
  left: 191px;
  width: 1130px;
  height: 340px;
  text-align: left;
  font-size: var(--font-size-348xl);
  color: var(--color-gray-200);
}
.numbers {
  position: relative;
  width: 100%;
  height: 952px;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--white);
  font-family: var(--font-inter);
}

}

/*Mobile*/
@media screen and (max-width: 940px) {

.iconLms {
position: relative;
top: 15px;
}

.LmsContent6txt {
 padding: 9.5px 8px;
}

.threeboxes {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 315px;
}

.threeboxesContainer {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

.threeboxesContainerBox {
  height: 80px;
  width: 280px;
  background-color: #ffffff;
  border-radius: 20px;
  margin: 20px auto;
  padding: 5px 20px;
  display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 16px;
    color: #212121;
}


.unionIcon1 {
  display: none;
}

.unionIcon2 {
  position: relative;
  width: 100%;
}

.whyGenxture {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 24px;
}
.genxtureFocusesOn {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 100%;
  opacity: 0.8;
  font-size: 14px;
}
.connect,
.div {
  position: absolute;
}
.div {
  width: 100%;
  top: 240px;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.connect {
  top: 150px;
  left: 35px;
  line-height: 45px;
  text-transform: uppercase;
  font-weight: 800;
  transform: rotate(270deg);
  font-size: 32px;
}
.buildTrustingRelationships {
  position: absolute;
  top: 44px;
  left: 35px;
  font-size: var(--font-size-xl);
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 336.47px;
  height: 85px;
  transform: rotate(270deg);
  font-size: 16px;
}

.div0 {

}

.div3 {
  height: calc(100% + 251px);
  width: calc(100% - 314.99px);
  top: -142px;
  left: 335.19px;
  font-size: var(--font-size-348xl);
  line-height: 277px;
  font-weight: 600;
  color: var(--color-gray-200);
  display: flex;
  align-items: center;
}
.div2,
.div3,
.div5 {
  position: absolute;
}
.div2 {
  /*width: calc(100% - 628px);*/
  width: 340px;
  top: 30px;
  right: 598px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #004ce8, #6f9eff);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  height: 280px;
  overflow: hidden;
  font-size: var(--font-size-21xl);
  color: var(--white);
}
.div5 {
  height: calc(100% + 251px);
  width: calc(100% + 68.97px);
  top: -142px;
  left: 11.23px;
  line-height: 277px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transform: rotate(270deg);
}
.div4 {
  top: 30px;
  left: 502px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #b0009c, #ff22e6);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
  overflow: hidden;
}
.div4,
.div6,
.div7 {
  position: absolute;
}
.div7 {
  height: calc(100% + 251px);
  width: calc(100% + 56.12px);
  top: -142px;
  left: 24.08px;
  line-height: 277px;
  font-weight: 800;
  display: flex;
  align-items: center;
  transform: rotate(270deg);
}
.div6 {
  top: 30px;
  left: 644px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #e36400, #ff984e);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
  overflow: hidden;
}
.div9 {
  height: calc(100% + 251px);
  width: calc(100% + 84.37px);
  top: -142px;
  left: -4.17px;
  line-height: 277px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transform: rotate(270deg);
}
.div11,
.div8,
.div9 {
  position: absolute;
}
.div8 {
  top: 30px;
  left: 786px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #ffd600, #ffe766);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
  overflow: hidden;
}
.div11 {
  height: calc(100% + 251px);
  width: calc(100% + 64.92px);
  top: -142px;
  left: 15.28px;
  line-height: 277px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transform: rotate(270deg);
}
.div1,
.div10 {
  position: absolute;
  border-radius: var(--br-xl);
  overflow: hidden;
}
.div10 {
  top: 30px;
  left: 928px;
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
}
.div1 {
    top: 540px;
    left:  calc(200px + 100vw - 954px) !important;
    width: 1130px;
    height: 340px;
    text-align: left;
    font-size: var(--font-size-348xl);
    color: var(--color-gray-200);
    transform: rotate(90deg);
}
.numbers {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 40px;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--white);
  font-family: var(--font-inter);
}

}  