/*Computer*/
@media screen and (min-width: 940px) {

 .imgdata1{
  width: 90%;
  margin: auto;
  top: 250px;
  position: relative;
 }

  .imgdata2{
  width: 90%;
  margin: auto;
  top: 250px;
  position: relative;
  display: none;
 }

.unionIcon1 {
  position: relative;
  width: 100%;
}

.unionIcon2 {
  position: relative;
  width: 100%;
  display: none;
}

.ourProcess {
  position: absolute;
  top: 300px;
  left: 580px;
  letter-spacing: -0.04em;
  font-weight: 600;
}
.divider {
  position: absolute;
  top: 166.5px;
  left: 60.5px;
  border-right: 1px solid var(--color-lavender);
  box-sizing: border-box;
  width: 1px;
  height: 765px;
  display: none;
}
.div,
.learning {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 600;
}
.learning {
  font-weight: 500;
  color: var(--white);
}
.parent,
.textInner {
  display: flex;
  justify-content: flex-start;
}
.parent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}
.textInner {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  flex-direction: column;
  padding: var(--padding-7xs) var(--padding-xl);
  align-items: flex-start;
  color: var(--color-gray-100);
}
.immersiveLearningExperience {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-13xl);
  line-height: 140%;
  font-weight: 600;
}
.withEmotioanalConnect {
  align-self: stretch;
  position: relative;
  line-height: 160%;
}
.text {
  position: absolute;
  top: 23px;
  left: 604px;
  width: 636px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.child,
.item {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.item {
  height: 91.8%;
  width: 91.8%;
  top: 7.7%;
  right: 4.1%;
  bottom: 0.49%;
  left: 4.1%;
}
.div1,
.div2,
.item {
  position: absolute;
}
.div2 {
  top: calc(50% - 21px);
  left: calc(50% - 21px);
  line-height: 130%;
  font-weight: 800;
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
}
.div1 {
  top: 89px;
  left: 0;
  width: 122px;
  height: 122px;
  text-align: center;
  font-size: var(--font-size-13xl);
}
.illustrationIcon,
.step {
  position: absolute;
  height: 300px;
}
.illustrationIcon {
  top: 0;
  left: 120px;
  width: 306px;
  overflow: hidden;
}
.step {
  top: 120px;
  left: 0;
  width: 1240px;
}
.div4 {
  top: 83px;
  left: 0;
  width: 122px;
  height: 122px;
  text-align: center;
  font-size: var(--font-size-13xl);
}
.div4,
.step1,
.text2 {
  position: absolute;
}
.step1 {
  top: 386px;
  left: 0;
  width: 1240px;
  height: 300px;
}
.text2 {
  top: 39px;
  left: 604px;
  width: 636px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.step2,
.steps {
  position: absolute;
  top: 0;
  left: 0;
  width: 1240px;
  height: 300px;
}
.steps {
  top: 220px;
  left: 136px;
  text-align: left;
  font-size: var(--font-size-xl);
}
.numbers {
  position: relative;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--white);
  font-family: var(--font-inter);
}


.LearningAppTypeBox {
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.LearningAppTypeBox1 {
     width: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.LearningAppTypeBox2 {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.LearningAppTypeBoxText {
  padding: 20px;
  font-size: 14px;
}


.genextureProvesIts {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
}
.knowledge {
  position: relative;
  line-height: 25px;
  font-weight: 500;
}
.a {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  width: 252px;
  flex-shrink: 0;
  padding: var(--padding-5xs) var(--padding-17xl);
  box-sizing: border-box;
  justify-content: center;
  color: var(--white);
}
.a,
.a1,
.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.a1 {
  border-radius: var(--br-11xl);
  width: 252px;
  flex-shrink: 0;
  padding: var(--padding-5xs) var(--padding-17xl);
  box-sizing: border-box;
  justify-content: center;
}
.tabs {
  border-radius: var(--br-42xl);
  background-color: var(--color-whitesmoke-200);
  padding: var(--padding-11xs);
  justify-content: flex-start;
  font-size: var(--font-size-xs);
}
.materialSymbolsplayCircleOIcon,
.screenChangeMe {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.screenChangeMe {
  height: 105.46%;
  width: 103.08%;
  top: -2.73%;
  right: -1.54%;
  bottom: -2.73%;
  left: -1.54%;
  border-radius: var(--br-13xl);
}
.materialSymbolsplayCircleOIcon {
  height: 19.67%;
  width: 11.09%;
  top: 40.16%;
  right: 44.53%;
  bottom: 40.16%;
  left: 44.38%;
}
.screenChangeMeParent {
  position: relative;
  width: 649px;
  height: 366px;
  flex-shrink: 0;
}
.helpYourChild {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
}
.getThemReady {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 537.03px;
  opacity: 0.6;
}
.knowMore {
  position: relative;
  line-height: 25px;
  font-weight: 600;
}
.a3,
.div {
  display: flex;
}
.a3 {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-17xl);
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--white);
}
.div {
  width: 50%;
  float: right;
  top: 120px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}



}



/*Mobile*/
@media screen and (max-width: 940px) {

 .imgdata1{
  width: 90%;
  margin: auto;
  top: 250px;
  position: relative;
  display: none;
 }



  .imgdata2{
  width: 100%;
  margin: auto;
  top: 150px;
  position: relative;
 }

.unionIcon1 {
  position: relative;
  width: 100%;
  height: 1707px;
  display: none;
}

.unionIcon2 {
  position: relative;
  width: 100%;
  height: 1707px;
}

.ourProcess {
  position: absolute;
  top: 60px;
  left: 75px;
  margin: auto;
  text-align: center;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 24px;
  display: none;
}
.divider {
  display: none;
}
.div,
.learning {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 600;
}
.learning {
  font-weight: 500;
  color: var(--white);
}
.parent,
.textInner {
  display: flex;
  justify-content: flex-start;
}
.parent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}
.textInner {
  margin: auto;
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  flex-direction: column;
  padding: var(--padding-7xs) var(--padding-xl);
  align-items: flex-start;
  color: var(--color-gray-100);
  position: relative;
  top: 500px;
}
.immersiveLearningExperience {
  align-self: stretch;
  position: relative;
  font-size: 24px;
  line-height: 140%;
  font-weight: 600;
}
.withEmotioanalConnect {
  align-self: stretch;
  position: relative;
  line-height: 160%;
  font-size: 14px;
}
.text {
  position: absolute;
  top: -400px;
  left: 35px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.child,
.item {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.item {
  height: 91.8%;
  width: 91.8%;
  top: 7.7%;
  right: 4.1%;
  bottom: 0.49%;
  left: 4.1%;
}
.div1,
.div2,
.item {
  position: absolute;
}
.div2 {
  top: calc(50% - 21px);
  left: calc(50% - 21px);
  line-height: 130%;
  font-weight: 800;
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
}
.div1 {
  top: -100px;
  left: 170px;
  width: 70px;
  height: 70px;
  text-align: center;
  font-size: var(--font-size-13xl);
  margin: auto;
}
.illustrationIcon,
.step {
  position: absolute;
  height: 300px;
}
.illustrationIcon {
  top: -820px;
  left: 45px;
  width: 242px;
  overflow: hidden;
}
.step {
  top: 760px;
  left: 0;
  width: 100%;
}
.div4 {
  top: -280px;
  left: 170px;
  width: 75px;
  height: 75px;
  text-align: center;
  font-size: var(--font-size-13xl);
}
.div4,
.step1,
.text2 {
  position: absolute;
}
.step1 {
  top: 800px;
  left: 0;
  width: 100%;
  height: 300px;
}
.text2 {
  top: 260px;
  left: 0px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.step2,
.steps {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  text-align: center;
}
.steps {
  top: 220px;
  left: 0px;
  height: 1060px;
  text-align: left;
  font-size: var(--font-size-xl);
}
.numbers {
  position: relative;
  width: 100%;
  height: 1840px;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--white);
  font-family: var(--font-inter);
}

.LearningAppTypeBox {
    height: 160px;
    width: 100%;
    left: 25px;
    position: relative;
}

.LearningAppTypeBox1 {
     width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.LearningAppTypeBox2 {
    position: relative;
    top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.LearningAppTypeBoxText {
  padding: 20px;
  font-size: 14px;
}


.genextureProvesIts {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 24px;
}
.knowledge {
  position: relative;
  line-height: 25px;
  font-weight: 500;
}
.a {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  width: 252px;
  flex-shrink: 0;
  padding: var(--padding-5xs) var(--padding-17xl);
  box-sizing: border-box;
  justify-content: center;
  color: var(--white);
}
.a,
.a1,
.tabs {
    flex: 1;
    border-radius: var(--br-base);
    flex-direction: column;
    padding: var(--padding-5xs) var(--padding-base);
    justify-content: center;
    color: var(--white);
    border-radius: 40px;
    padding: 10px;
    margin: auto;
}
.a1 {
    flex: 1;
    border-radius: var(--br-base);
    display: flex;
    flex-direction: column;
    padding: var(--padding-5xs) var(--padding-base);
    align-items: center;
    justify-content: center;
}
.tabs {
 border-radius: var(--br-16xl);
    background-color: var(--color-whitesmoke-200);
    width: 90%;
    margin: 10px auto;
    padding: 10px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-11xs);
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-end;
    font-size: var(--font-size-xs);
    border-radius: 40px;
    color: black;
}
.materialSymbolsplayCircleOIcon,
.screenChangeMe {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.screenChangeMe {
  margin-top: 80px;
  height: 105.46%;
  width: 103.08%;
  right: -1.54%;
  bottom: -2.73%;
  left: -1.54%;
  border-radius: var(--br-13xl);
}
.materialSymbolsplayCircleOIcon {
  height: 19.67%;
  width: 11.09%;
  top: 40.16%;
  right: 44.53%;
  bottom: 40.16%;
  left: 44.38%;
}
.screenChangeMeParent {
  position: relative;
  width: 100%;
  height: 280px;
  flex-shrink: 0;
  cursor: pointer;
}
.helpYourChild {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 20px;
  padding: 0px 25px;
}
.getThemReady {
  position: relative;
  line-height: 32px;
  display: inline-block;
  width: 90%;
  opacity: 0.6;
  padding: 0px 25px;
  font-size: 14px;
}
.knowMore {
  position: relative;
  line-height: 25px;
  font-weight: 600;
}
.a3,
.div {
  display: flex;
}
.a3 {
  border-radius: var(--br-11xl);
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-17xl);
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--white);
  margin: 0px 25px;
  cursor: pointer;
}
.div {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  margin-top: 40px;
}


}