.students {
  position: relative;
  line-height: 23px;
}
.aafterIcon {
  position: relative;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}
.text {
  position: relative;
  line-height: 23px;
  font-family: var(--font-jost);
}
.a {
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xl) var(--padding-4xs) var(--padding-5xl)
    var(--padding-sm);
  align-items: center;
  justify-content: center;
  gap: 4.11px;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--white);
  font-family: var(--font-inter);
}
