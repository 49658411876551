/*Computer*/
@media screen and (min-width: 940px) {

.BackContentImg {
  display: none;
}


.div4:focus {
 left: 0;
 width: 530px;
 border-top-right-radius: 0;
 border-bottom-right-radius: 0;
 transition: transform 0.2s ease-in-out;
 transform: translateX(0);
 z-index: 0;
}

.div6:focus {
 left: 0;
 width: 530px;
 border-top-right-radius: 0;
 border-bottom-right-radius: 0;
 transition: transform 0.2s ease-in-out;
 transform: translateX(0);
 z-index: 0;
}

.div8:focus {
 left: 0;
 width: 530px;
 border-top-right-radius: 0;
 border-bottom-right-radius: 0;
 transition: transform 0.2s ease-in-out;
 transform: translateX(0);
 z-index: 0;
}

.div10:focus {
 left: 0;
 width: 530px;
 border-top-right-radius: 0;
 border-bottom-right-radius: 0;
 transition: transform 0.2s ease-in-out;
 transform: translateX(0);
 z-index: 0;
}

.div4:focus ~ .div5 {
  left: 300px;
}

.div4, .div6, .div8, .div10 {
   transform: translateX(10px);
}

.div4, .div6, .div8, .div10 {
  z-index: 333333;
  cursor: pointer;
}

.unionIcon1 {
  position: relative;
  width: 100%;
  height: 952px;
}

.unionIcon2 {
  position: relative;
  width: 100%;
  height: 952px;
  display: none;
}

.whyGenxture {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
}
.genxtureFocusesOn {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 611px;
  opacity: 0.8;
}
.connect,
.div {
  position: absolute;
}
.div {
  top: 246px;
  left: 290px;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.connect {
  top: 50px;
  left: 35px;
  line-height: 45px;
  text-transform: uppercase;
  font-weight: 800;
}

.connectHide {
  top: 40px;
  left: 180px;
  position: absolute;
  font-size: 28px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}

.buildTrustingRelationships {
  position: absolute;
  top: 130px;
  left: 35px;
  font-size: var(--font-size-xl);
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 380px;
  height: 85px;
}

.buildTrustingRelationshipsHide {
  position: absolute;
  top: 140px;
  left: 180px;
  font-size: var(--font-size-xl);
  line-height: 28px;
  display: flex;
  align-items: center;
  width: 310px;
  height: 85px;
  color: #ffffff;
}

.div3 {
  height: calc(100% + 251px);
  width: calc(100% - 314.99px);
  top: -142px;
  left: 335.19px;
  font-size: var(--font-size-348xl);
  line-height: 277px;
  font-weight: 600;
  color: var(--color-gray-200);
  display: flex;
  align-items: center;
}
.div2,
.div3,
.div5 {
  position: absolute;
}
.div2 {
  width: calc(100% - 628px);
  top: 30px;
  right: 598px;
  left: 30px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #004ce8, #6f9eff);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  height: 280px;
  overflow: hidden;
  font-size: var(--font-size-21xl);
  color: var(--white);
}
.div5 {
  height: calc(100% + 251px);
  width: calc(100% + 68.97px);
  top: -143px;
  left: -50px;
  line-height: 277px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.div4 {
  top: 30px;
  left: 502px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #b0009c, #ff22e6);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
  overflow: hidden;
}

.div41 {
  top: 30px;
  left: 512px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #004ce8, #6f9eff);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
  overflow: hidden;
  position: absolute;
}

.div4,
.div6,
.div7 {
  position: absolute;
}

.div41,
.div62,
 {
  position: absolute;
}

.div7 {
  height: calc(100% + 251px);
  width: calc(100% + 56.12px);
  top: -142px;
  left: -50px;
  line-height: 277px;
  font-weight: 800;
  display: flex;
  align-items: center;
}
.div6 {
  top: 30px;
  left: 644px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #e36400, #ff984e);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
  overflow: hidden;
}

.div62 {
  top: 30px;
  left: 653px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #004ce8, #6f9eff);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
  overflow: hidden;
  position: absolute;
}

.div9 {
  height: calc(100% + 251px);
  width: calc(100% + 84.37px);
  top: -142px;
  left: -50px;
  line-height: 277px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.div11,
.div8,
.div9 {
  position: absolute;
}

.div83 {
  position: absolute;
}

.div8 {
  top: 30px;
  left: 776px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #ffd600, #ffe766);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
  overflow: hidden;
}

.div83 {
  top: 30px;
  left: 786px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #004ce8, #6f9eff);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
  overflow: hidden;
}

.div11 {
  height: calc(100% + 251px);
  width: calc(100% + 64.92px);
  top: -142px;
  left: -50px;
  line-height: 277px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.div1,
.div10 {
  position: absolute;
  border-radius: var(--br-xl);
  overflow: hidden;
}

.div104 {
  position: absolute;
  border-radius: var(--br-xl);
  overflow: hidden;
}

.div10 {
  top: 30px;
  left: 918px;
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
}

.div104 {
  top: 30px;
  left: 928px;
  background: linear-gradient(-73.83deg, #004ce8, #6f9eff);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 280px;
}

.div1 {
  top: 408px;
  left: 191px;
  width: 1130px;
  height: 340px;
  text-align: left;
  font-size: var(--font-size-348xl);
  color: var(--color-gray-200);
}
.numbers {
  position: relative;
  width: 100%;
  height: 952px;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--white);
  font-family: var(--font-inter);
}

}

/*Mobile*/
@media screen and (max-width: 940px) {

.BackContentImg {
  width: 145%;
  opacity: 0;
  left: -20px;
  position: absolute;
  top: 5px;
  z-index: 2;
  transform: rotate(270deg);
}


.div02 {
  transform: rotate(270deg);
}

.div4:focus {
 left: 180px;
 width: 350px;
 border-top-right-radius: 0;
 border-bottom-right-radius: 0;
 transition: transform 0.2s ease-in-out;
 transform: translateX(0);
 z-index: 1;
}

.div4:focus img{
 opacity: 1;
}

.div4:focus h1{
 opacity: 1;
 text-transform: uppercase;
}

.div4:focus p{
 opacity: 1;
 top: -20px;
 width: 85%;
 left: -130px;
 position: absolute;
}


.div6:focus {
 left: 180px;
 width: 350px;
 border-top-right-radius: 0;
 border-bottom-right-radius: 0;
 transition: transform 0.2s ease-in-out;
 transform: translateX(0);
 z-index: 1;
}

.div6:focus img{
 opacity: 1;
}

.div6:focus h1{
 opacity: 1;
 text-transform: uppercase;
}

.div6:focus p{
 opacity: 1;
 top: -20px;
 width: 85%;
 left: -130px;
 position: absolute;
}

.div8:focus {
 left: 180px;
 width: 350px;
 border-top-right-radius: 0;
 border-bottom-right-radius: 0;
 transition: transform 0.2s ease-in-out;
 transform: translateX(0);
 z-index: 1;
}

.div8:focus img{
 opacity: 1;
}

.div8:focus h1{
 opacity: 1;
 text-transform: uppercase;
}

.div8:focus p{
 opacity: 1;
 top: -20px;
 width: 85%;
 left: -130px;
 position: absolute;
}


.div10:focus {
 left: 180px;
 width: 350px;
 border-top-right-radius: 0;
 border-bottom-right-radius: 0;
 transition: transform 0.2s ease-in-out;
 transform: translateX(0);
 z-index: 1;
}

.div10:focus img{
 opacity: 1;
}

.div10:focus h1{
 opacity: 1;
 text-transform: uppercase;
}

.div10:focus p{
 opacity: 1;
 top: -20px;
 width: 85%;
 left: -130px;
 position: absolute; 
}

.div4, .div6, .div8, .div10 {
   transform: translateX(10px);
}

.div4, .div6, .div8, .div10 {
  z-index: 333333;
  cursor: pointer;
  width: 80%;
  left: -141px;
  position: absolute;
  color: white;
}

.div41, .div62, .div83, .div104 {
  position: absolute;
  background: linear-gradient(-73.83deg, #004ce8, #6f9eff);
  color: rgba(255, 255, 255, 0.2);
  font-size: 300px;
}

.homeContentOpacity {
  opacity: 0;
}

.unionIcon2 {
  position: relative;
  width: 100%;
  height: auto;
  left: 0;
}


.unionIcon1 {
  position: relative;
  width: 100%;
  height: 952px;
  display: none;
}

.whyGenxture {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 24px;
}
.genxtureFocusesOn {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 32px;
  display: inline-block;
  width: 100%;
  opacity: 0.8;
  font-size: 14px;
}
.connect,
.div {
  position: absolute;
}
.div {
  width: 100%;
  top: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-10xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.connect {
  top: 180px;
  left: 0px;
  line-height: 45px;
  text-transform: uppercase;
  font-weight: 800;
  transform: rotate(270deg);
  font-size: 32px;
}


.connectHide {
  top: -70px;
  left: -130px;
  position: absolute;
  font-size: 28px;
  font-weight: bold;
  color: white;
  opacity: 0;
}

.buildTrustingRelationships {
  position: absolute;
  top: 100px;
  left: 35px;
  font-size: var(--font-size-xl);
  line-height: 28px;
  display: flex;
  align-items: center;
  height: 85px;
  transform: rotate(270deg);
  font-size: 16px;
}

.div0 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.div3 {
  height: calc(100% + 251px);
  width: calc(100% - 314.99px);
  top: -215px;
  left: 335.19px;
  font-size: var(--font-size-348xl);
  line-height: 277px;
  font-weight: 600;
  color: var(--color-gray-200);
  display: flex;
  align-items: center;
}
.div2,
.div3,
.div5 {
  position: absolute;
}

 
.div3 {
  position: absolute;
  font-size: 386px;
  left: 120px;
  top: -180px;
  transform: rotate(270deg);
}

.div2 {
  /*width: calc(100% - 628px);*/
  width: 350px;
  right: 598px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #004ce8, #6f9eff);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  height: 360px;
  overflow: hidden;
  font-size: var(--font-size-21xl);
  color: var(--white);
}
.div5 {
  height: calc(100% + 251px);
  width: calc(100% + 68.97px);
  top: -340px;
  left: 11.23px;
  line-height: 277px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transform: rotate(270deg);
  color: rgba(255, 255, 255, 0.2);
  font-size: 300px;
}
.div4 {
  left: 505px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #b0009c, #ff22e6);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 170px;
  height: 636px;
  overflow: hidden;
}

.div41 {
  left: 515px;
  border-radius: var(--br-xl);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 636px;
  overflow: hidden;
}

.div4,
.div6,
.div7 {
  position: absolute;
}

.div41,
.div62,
 {
  position: absolute;
}


.div7 {
  height: calc(100% + 251px);
  width: calc(100% + 56.12px);
  top: -355px;
  left: 24.08px;
  line-height: 277px;
  font-weight: 800;
  display: flex;
  align-items: center;
  transform: rotate(270deg);
  color: rgba(255, 255, 255, 0.2);
  font-size: 300px;
}
.div6 {
  left: 635px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #e36400, #ff984e);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 170px;
  height: 636px;
  overflow: hidden;
}

.div62 {
  left: 644px;
  border-radius: var(--br-xl);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 636px;
  overflow: hidden;
}

.div9 {
  height: calc(100% + 251px);
  width: calc(100% + 84.37px);
  top: -350px;
  left: -4.17px;
  line-height: 277px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transform: rotate(270deg);
  color: rgba(255, 255, 255, 0.2);
  font-size: 300px;
}
.div11,
.div8,
.div9 {
  position: absolute;
}
.div8 {
  left: 777px;
  border-radius: var(--br-xl);
  background: linear-gradient(-73.83deg, #ffd600, #ffe766);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 170px;
  height: 636px;
  overflow: hidden;
}

.div83 {
  left: 786px;
  border-radius: var(--br-xl);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 636px;
  overflow: hidden;
  position: absolute;
}

.div11 {
  height: calc(100% + 251px);
  width: calc(100% + 64.92px);
  top: -350px;
  left: 15.28px;
  line-height: 277px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transform: rotate(270deg);
  color: rgba(255, 255, 255, 0.2);
  font-size: 300px;
}
.div1,
.div10 {
  position: absolute;
  border-radius: var(--br-xl);
  overflow: hidden;
}
.div10 {
  left: 918px;
  background: linear-gradient(-73.83deg, #66a5fa, #723ac5);
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 636px;
}

.div104 {
  position: absolute;
  border-radius: var(--br-xl);
  overflow: hidden;
}
.div104 {
  left: 928px;
  box-shadow: 0 5.594289779663086px 34.96px rgba(0, 0, 0, 0.1);
  width: 172px;
  height: 636px;
}

.div1 {
    top: 540px;
   /* left:  calc(200px + 100vw - 954px) !important; */
    width: 1130px;
    height: 20%;
    text-align: left;
    font-size: var(--font-size-348xl);
    color: var(--color-gray-200);
    transform: rotate(90deg);
    font-size: 18px;
}
.numbers {
  position: relative;
  width: 100%;
  height: 1600px;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--white);
  font-family: var(--font-inter);
}

}  