.imageIcon {
  
  width: 230px;
  height: 295px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 15px;
}
.marvinMckinney,
.productManager {
  position: relative;
  line-height: 160%;
  font-weight: 600;
  display: inline-block;
  width: 260px;
  font-size: 30px;
}
.productManager {
  font-size: var(--font-size-lg);
  font-weight: 500;
  opacity: 0.6;
}
.info {
  /*position: absolute;*/
  top: 321.48px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  margin-bottom:30px;
}
.teamCard {
  align-self: stretch;
  position: relative;
  width: 265px;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--text);
  font-family: var(--font-inter);
}

@media only screen and (max-width: 940px) {

  .teamCard {
    width: 100%;
  }
}
